import { apiSlice } from "../../api/apiSlice";

export const eventsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getEvents: builder.query({
            query: () => ({
                url: '/api/events/get-all-events',
                method: 'GET',
            })
        }),
        getEventById: builder.query({
            query: (id) => ({
                url: `/api/events/get-event-by-id/${id}`,
                method: 'GET',
            })
        }),
        addEvent: builder.mutation({
            query: (newEvent) => ({
                url: '/api/events/create-event',
                method: 'POST',
                body: newEvent,
            })
        }),
        updateEvent: builder.mutation({
            query: ({ id, updatedEvent }) => ({
                url: `/api/events/update-event/${id}`,
                method: 'PUT',
                body: updatedEvent,
            })
        }),
        deleteEvent: builder.mutation({
            query: (id) => ({
                url: `/api/events/update-event-visibility/${id}`,
                method: 'PATCH',
            })
        }),

        getActiveEventNames: builder.query({
            query: () => ({
                url: '/api/events/get-event-names',
                method: 'GET',
            })
        }),


        getActivities: builder.query({
            query: () => ({
                url: '/api/events/get-all-activities',
                method: 'GET',
            })
        }),
        getActivityById: builder.query({
            query: (id) => ({
                url: `/api/events/get-activity-by-id/${id}`,
                method: 'GET',
            })
        }),
        addActivity: builder.mutation({
            query: (newActivity) => ({
                url: '/api/events/create-activity',
                method: 'POST',
                body: newActivity,
            })
        }),
        updateActivity: builder.mutation({
            query: ({ id, updatedActivity }) => ({
                url: `/api/events/update-activity/${id}`,
                method: 'PUT',
                body: updatedActivity,
            })
        }),
        deleteActivity: builder.mutation({
            query: (id) => ({
                url: `/api/events/update-activity-visibility/${id}`,
                method: 'PATCH',
            })
        }),
        getActiveIndustries: builder.query({
            query: () => ({
                url: '/api/events/get-active-industries',
                method: 'GET',
            })
        }),
        getAllActivities: builder.query({
            query: () => ({
                url: '/api/userevents/get-all-activities',
                method: 'GET',
            })
        }),
        getActivityAvailableTimesMentor: builder.query({
            query: (activityId) => ({
                url: `/api/userevents/get-activity-available-times-mentor/${activityId}`,
                method: 'GET',
            })
        }),
        assignMentorToBlock: builder.mutation({
            query: ({ mentorId, blockId }) => ({
                url: `/api/userevents/assign-mentor-to-block`,
                method: 'POST',
                params: { mentorId, blockId },
            })
        }),
        assignMenteeToBlock: builder.mutation({
            query: ({ menteeId, blockId }) => ({
                url: `/api/userevents/assign-mentee-to-block`,
                method: 'POST',
                params: { menteeId, blockId },
            })
        }),
        getUserRolesEvents: builder.query({
            query: (userId) => ({
                url: `/api/userevents/get-user-roles-events/${userId}`,
                method: 'GET',
            })
        }),

        getActivityAvailableTimesMentee: builder.query({
            query: (activityId) => `/api/userevents/get-activity-available-times-mentee/${activityId}`,
        }),

        getUpcomingActivitiesByUser: builder.query({
            query: (userId) => ({
                url: `/api/userevents/get-upcoming-activities-by-user/${userId}`,
                method: 'GET',
            })
        }),
        
        cancelUpcomingActivityByUser: builder.mutation({
            query: ({ activityId, userId }) => ({
                url: `/api/userevents/cancel-upcoming-activity-user/${activityId}/${userId}`,
                method: 'PATCH',
            })
        }),
        checkUserInTicketSalesEtMtuesday: builder.query({
            query: (userId) => ({
                url: `/api/userevents/check-user-in-ticket-sales-etmtuesday/${userId}`,
                method: 'GET',
            })
        }),

        confirmAssist: builder.mutation({
            query: ({ userId, qrCode }) => ({
                url: `/api/userevents/confirm-assist`,
                method: 'PATCH',
                params: { userId, qrCode },
            })
        }),

        getActiveActivityTypes: builder.query({
            query: () => '/api/events/get-activity-types',
        }),
        getAllAvailableTimes: builder.query({
            query: () => ({
                url: '/api/events/get-all-available-times',
                method: 'GET',
            })
        }),

        sendActivityFeedback: builder.mutation({
            query: (feedbackData) => ({
                url: '/api/userevents/send-activity-feedback',
                method: 'POST',
                body: feedbackData,
            })
        }),
        assignMentorByAdmin: builder.mutation({
            query: ({ email, blockId }) => ({
                url: '/api/events/assign-mentor-by-admin',
                method: 'POST',
                params: { email, blockId },
            })
        }),

        assignMenteeByAdmin: builder.mutation({
            query: ({ email, blockId }) => ({
                url: '/api/events/assign-mentee-by-admin',
                method: 'POST',
                params: { email, blockId },
            })
        }),
        cancelMenteeByAdmin: builder.mutation({
            query: ({ email, blockId }) => ({
                url: '/api/events/cancel-mentee-by-admin',
                method: 'POST',
                params: { email, blockId },
            })
        }),
        cancelMentorByAdmin: builder.mutation({
            query: ({ email, blockId }) => ({
                url: '/api/events/cancel-mentor-by-admin',
                method: 'POST',
                params: { email, blockId },
            })
        }),
        getActivityHistoryByUser: builder.query({
            query: (userId) => ({
                url: `/api/userevents/get-activity-history-by-user/${userId}`,
                method: 'GET',
            })
        }),
        getActivitiesByIdsFeedback: builder.query({
            query: ({ id1, id2 }) => ({
                url: `/api/userevents/get-activities-by-ids-feedback/${id1}/${id2}`,
                method: 'GET',
            })
        }),
        


    })
});

export const {
    useGetEventsQuery,
    useGetEventByIdQuery,
    useAddEventMutation,
    useUpdateEventMutation,
    useDeleteEventMutation,
    useGetActiveEventNamesQuery,
    //actividades
    useGetActivitiesQuery,
    useGetActivityByIdQuery,
    useAddActivityMutation,
    useUpdateActivityMutation,
    useDeleteActivityMutation,
    useGetActiveIndustriesQuery,

    //userevents

    useGetAllActivitiesQuery,
    useGetActivityAvailableTimesMentorQuery,
    useAssignMentorToBlockMutation,
    useAssignMenteeToBlockMutation,
    useGetActivityAvailableTimesMenteeQuery,

    //GETROL-events => "events solo para diferenciar del otro, que es una mutation"

    useGetUserRolesEventsQuery,

    // upcoming activities

    useGetUpcomingActivitiesByUserQuery,

    // cancel activitiess
    useCancelUpcomingActivityByUserMutation,

    useCheckUserInTicketSalesEtMtuesdayQuery,
    useConfirmAssistMutation,
    useGetActiveActivityTypesQuery,
    useGetAllAvailableTimesQuery,
    useSendActivityFeedbackMutation,
    useAssignMentorByAdminMutation,
    useAssignMenteeByAdminMutation,
    useCancelMenteeByAdminMutation,
    useCancelMentorByAdminMutation,
    useGetActivityHistoryByUserQuery,
    useGetActivitiesByIdsFeedbackQuery
} = eventsApiSlice;
