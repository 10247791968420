import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials, logOut } from '../features/auth/authSlice'

const baseQuery = fetchBaseQuery({

    // Documentacion
    // https://s5oonjg8k3.execute-api.us-east-1.amazonaws.com/Prod/Swagger

    // URL BASE
    baseUrl: 'https://s5oonjg8k3.execute-api.us-east-1.amazonaws.com/Prod',
    //baseUrl: 'https://localhost:61148',
    //baseUrl: 'http://localhost:61149',



    /* credentials: 'include', */
    credentials: 'same-origin',
    prepareHeaders: (headers, { getState }: any) => {
        const token: any = getState().auth.token
        if (token) {
            headers.set("authorization", `Bearer ${token}`);
        }
        /* headers.set("Content-Type", "application/json"); */
        headers.set('Access-Control-Allow-Origin', '*');
        headers.set('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, OPTIONS');
            
        headers.set('Access-Control-Allow-Credentials', 'true');
        return headers
    }
})

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
    let result = await baseQuery(args, api, extraOptions)

    if (result?.error?.status === 403) {
        // send refresh token to get new access token 
        const refreshResult = await baseQuery('/refresh', api, extraOptions)
        if (refreshResult?.data) {
            const user = api.getState().auth.user
            // store the new token 
            api.dispatch(setCredentials({ ...refreshResult.data, user }))
            // retry the original query with new access token 
            result = await baseQuery(args, api, extraOptions)
        } else {
            api.dispatch(logOut({}))
        }
    }

    return result
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({})
})




