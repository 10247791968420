import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectPersonalData } from '../../redux/features/user/userSlice';

const adminIds = [19044, 19131, 1469, 20003, 20828];

const HourDonation = () => {
  const [email, setEmail] = useState('');

  const navigate = useNavigate();
  const userPersonalData = useSelector(selectPersonalData);

  useEffect(() => {
    if (!adminIds.includes(userPersonalData.Id)) {
      navigate('/home');
    }
  }, [userPersonalData, navigate]);

  if (!adminIds.includes(userPersonalData.Id)) {
    return null;
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Donación de horas
      </Typography>
      <TextField
        label="Correo Electrónico"
        variant="outlined"
        fullWidth
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        sx={{ mb: 2 }}
      />
      <Button variant="contained" color="primary">
        Seleccionar hora
      </Button>
    </Box>
  );
};

export default HourDonation;
