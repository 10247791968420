import './UserProfileExternal.scss';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Importar useDispatch
import { selectPersonalData } from '../../redux/features/user/userSlice';
import { personalData } from '../../interfaces/dimentions';
import { Dialog, DialogContent, DialogTitle, DialogActions, useMediaQuery, useTheme, Button } from '@mui/material';
import FormularioSolicitarReunion from './FormularioSolicitarReunion';
import UserProfileExternalTopCard from './UserProfileExternalTopCard';
import { useGetUserStartupsMutation } from '../../redux/features/startups/startupsApiSlice';
import { useGetUserExpertisesMutation, useGetUserLanguagesMutation, useGetUserPublicDataMutation, useGetUserRolesMutation, useGetUserTagsMutation } from '../../redux/features/user/userApiSlice';
import UserProfileExternalStartups from './UserProfileExternalStartups';
import UserProfileExternalExperiencias from './UserProfileExternalExperiencias';
import UserProfileExternalIdiomasTags from './UserProfileExternalIdiomasTags';
import BottomNav from '../../components/Navegation/BottomNav/BottomNav';
import { usePostAddUserContactMutation } from '../../redux/features/user/userApiSlice';
import { useGetActivitiesByIdsFeedbackQuery, useSendActivityFeedbackMutation } from '../../redux/features/events/eventsApiSlice'; // Importar la mutación
import GetUserDonatedTime from '../../hooks/meetings/GetUserDonatedTime/GetUserDonatedTime';
import FormularioSolicitarTiempo from './FormularioSolicitarTiempo';
import { useGetUserFeedbacksMutation } from '../../redux/features/meetings/meetingsApiSlice';
import { selectCurrentDictonary } from '../../redux/features/language/LanguageSlice';
import { DialogTiempoReunion } from './DialogTiempoReunion';
import FeedbackDialog from '../Events/FeedbackDialog';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice'; // Importar funciones de alerta

function useQuery() {
    const { search } = useLocation();
    return new URLSearchParams(search);
}

const UserProfileExternal = ({ user_id_param, handleClickCloseDialogPublicProfile, handleLoadContactsMain }: any) => {
    const params: any = useParams();
    const query = useQuery(); // ProfileQR
    const mode = query.get('mode');  // ProfileQR
    const textPublicProfile = useSelector(selectCurrentDictonary).public_profile;
    const navigate = useNavigate(); // Hook para navegación
    const dispatch = useDispatch(); // Inicializar useDispatch

    const user_personal_data: personalData = useSelector(selectPersonalData); // acceder a datos del usuario logueado
    const user_id = user_personal_data?.Id || 0;

    let default_user = -1;
    let isExternal = 0;
    let isModal = 0;
    let clase_external = "";

    const [isModalQROpen, setIsModalQROpen] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);

    const [sendActivityFeedback] = useSendActivityFeedbackMutation();  // Mutación de feedback

    // Llamada a la API para obtener los datos de feedback
    const { data: feedbackData, isFetching: isFetchingFeedbackData } = useGetActivitiesByIdsFeedbackQuery({
        id1: user_id,
        id2: parseInt(params.userid)
    }, {
        skip: !(mode === "QR" && params.userid)  // Evitar el fetch si no se cumplen las condiciones
    });


    const [isMentor, setIsMentor] = useState(false); // Usar useState para isMentor
    const [isMentee, setIsMentee] = useState(false); // Usar useState para isMentee

    useEffect(() => {
        if (mode === "QR" && !isFetchingFeedbackData && feedbackData?.puede_dar_feedback) {
            const { mentorId, menteeId, mentorFeedbackId, menteeFeedbackId, mentorName, menteeName } = feedbackData.availableTime;


            const isUserMentor = user_id === mentorId; // Verificar directamente
            const isUserMentee = user_id === menteeId; // Verificar directamente

            // Determinar si el usuario actual es mentor o mentee
            //setIsMentor(user_id === mentorId); // Actualizar usando setIsMentor
            //setIsMentee(user_id === menteeId); // Actualizar usando setIsMentee

            // Validar si el feedback ya ha sido proporcionado
            //console.log(feedbackData);
            //console.log(mentorFeedbackId);
            //console.log(menteeFeedbackId);
            //console.log(user_id === mentorId);
            //console.log(user_id === menteeId);
            //console.log('Es Mentor: ' + isMentor);
            //console.log('Es Mentee: ' + isMentee);
//console.log(isMentor ? feedbackData.availableTime.menteeName : feedbackData.availableTime.mentorName);


            if ((isUserMentor && mentorFeedbackId === null) || (isUserMentee && menteeFeedbackId === null)) {
                setShowFeedbackDialog(true);
            } else {
                //console.log('Feedback ya proporcionado o no permitido.');
            }
    
            // Actualizar estados para referencia futura
            setIsMentor(isUserMentor);
            setIsMentee(isUserMentee);

        }
    }, [mode, user_id, navigate, feedbackData, isFetchingFeedbackData]);

    const handleFeedbackSubmit = async (values: any) => {
        if (!feedbackData || !feedbackData.availableTime) return; // Verificar que haya datos disponibles
        console.log(feedbackData.availableTime.id);

        const feedbackDataToSend = {
            ...values,
            availableTimeId: feedbackData.availableTime.id,
            isMentor,  // Si es mentor o no
        };

        // Crear alerta de envío de feedback
        let new_alert = { id: `sending_feedback_${Date.now()}`, type: "loading", title: "Enviando feedback", desc: "", close: false, timeout: 0 };
        dispatch(addAlert({ alert: new_alert }));

        try {
            // Enviar feedback utilizando la mutación
            await sendActivityFeedback(feedbackDataToSend).unwrap();
            // Eliminar alerta de carga y agregar alerta de éxito
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let success_alert = { id: `feedback_sent_${Date.now()}`, type: "success", title: "Feedback enviado", desc: "El feedback ha sido enviado exitosamente", close: true, timeout: 5000 };
            dispatch(addAlert({ alert: success_alert }));
            // Cerrar el diálogo de feedback
            setShowFeedbackDialog(false);
        } catch (error) {
            // Eliminar alerta de carga y agregar alerta de error
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let error_alert = { id: `feedback_error_${Date.now()}`, type: "error", title: "Error al enviar feedback", desc: "Hubo un error al enviar el feedback", close: true, timeout: 5000 };
            dispatch(addAlert({ alert: error_alert }));
            console.error('Error al enviar feedback', error);
        }
    };

    const handleConfirmAction = (choice: string) => {
        setOpenConfirmationDialog(false);
        if (choice === 'feedback') {
            setShowFeedbackDialog(true);
        }
    };

    if (!params.userid || params.userid == null || parseInt(params.userid) == user_id) { // no hay parametros en la ruta
        if (user_id_param && user_id_param > 0) { // perfil publico dentro de modal
            default_user = user_id_param;
            isExternal = 1;
            isModal = 1;
            clase_external = "interno";
        } else { // perfil personal
            default_user = user_id;
            isExternal = 0;
            clase_external = "interno";
        }
    } else { // hay parametros en la ruta
        isExternal = 1;
        isModal = 0;
        clase_external = "user-profile-container-external";
        clase_external = "externo body-container";

        default_user = parseInt(params.userid);
    }
    const user_id_profile: number = default_user;

    /* -------------- SOLICITUDES DE DATOS ---------------------------------------------------------------------- */
    const [getUserStartups, { isLoading: isLoadingGetUserStartups }]: any = useGetUserStartupsMutation();
    const [user_startups, setUserStartups] = useState([]);

    const fetchUserStartups = async (user_id: number) => {
        try {
            const res: any = await getUserStartups({ user_id: user_id });
            const resdata = JSON.parse(res.data.responseData);
            setUserStartups(resdata);
        } catch (error) {
            console.log(error);
        }
    };

    const [addUserContact] = usePostAddUserContactMutation();

    const handleAddContact = async () => {
        try {
            const userId = user_personal_data.Id;
            const contactUserId = user_id_profile;
            await addUserContact({ userId, contactUserId }).unwrap();
            setIsModalQROpen(false);
        } catch (error) {
            console.error('Error al añadir contacto', error);
        }
    };

    /* Experiencia laboral */
    const [getUserExpertises, { isLoading: isLoadingGetUserExpertises }]: any = useGetUserExpertisesMutation();
    const [user_expertises, setUserExpertises] = useState([]);

    const fetchUserExpertises = async (user_id: number) => {
        try {
            const res: any = await getUserExpertises({ user_id: user_id });
            const resdata = JSON.parse(res.data.responseData);
            setUserExpertises(resdata);
        } catch (error) {
            console.log(error);
        }
    };

    /* public data */
    const [getUserPublicData, { isLoading: isLoadingGetUserPublicData }]: any = useGetUserPublicDataMutation();
    const [user_public_data, setUserPublicData] = useState<any>({});

    const fetchUserPublicData = async (user_id: number) => {
        try {
            const res: any = await getUserPublicData({ user_id: user_id });
            const resdata = JSON.parse(res.data.responseData);
            setUserPublicData(resdata);
        } catch (error) {
            console.log(error);
        }
    };

    /* Tags */
    const [getUserTags, { isLoading: isLoadingGetUserTags }]: any = useGetUserTagsMutation();
    const [user_tags, setUserTags] = useState<any>({});

    const fetchUserTags = async (user_id: number) => {
        try {
            const res: any = await getUserTags({ user_id: user_id });
            const resdata = JSON.parse(res.data.responseData);
            setUserTags(resdata);
        } catch (error) {
            console.log(error);
        }
    };

    const [getUserLanguages, { isLoading: isLoadingGetUserLanguages }]: any = useGetUserLanguagesMutation();
    const [user_languages, setUserLanguages] = useState<any>({});

    const fetchUserLanguages = async (user_id: number) => {
        try {
            const res: any = await getUserLanguages({ user_id: user_id });
            const resdata = JSON.parse(res.data.responseData);
            setUserLanguages(resdata);
        } catch (error) {
            console.log(error);
        }
    };

    const [getUserRoles, { isLoading: isLoadingGetUserRoles }]: any = useGetUserRolesMutation();
    const [user_roles, setUserRoles] = useState<any>({});
    const [esEmprendedor, setEsEmprendedor] = useState<any>(false);
    const [esExperto, setEsExperto] = useState<any>(false);

    const fetchRoles = async (user_id: number) => {
        try {
            const res: any = await getUserRoles({ user_id: user_id });
            const resdata = JSON.parse(res.data.responseData);
            let arr: any = [];
            resdata.forEach((element: any) => {
                if (!resdata.some((x: any) => (x.id === element.RolId && element.Active == 1))) {
                    if (element.RolId == 1 && element.Active == 1) {
                        setEsEmprendedor(true);
                        arr.push({ name: "Emprendedor", id: element.RolId });
                    }
                    if (element.RolId == 6 && element.Active == 1) {
                        setEsExperto(true);
                        arr.push({ name: "Experto", id: element.RolId });
                    }
                }
            });
            setUserRoles(arr);
        } catch (error) {
            console.log(error);
        }
    };

    /* Feedbacks */
    const [getUserFeedbacks, { isLoading: isLoadingGetUserFeedbacks }]: any = useGetUserFeedbacksMutation();
    const [user_feedbacks, setUserFeedbacks] = useState<any>({});
    const [user_feedbacks_prom_estrellas, setUserFeedbacksPromEstrellas] = useState<any>(null);

    const fetchUserFeedbacks = async (user_id: number) => {
        setUserFeedbacksPromEstrellas(null);
        try {
            const res: any = await getUserFeedbacks({ user_id: user_id });
            const resdata: any = JSON.parse(res.data.responseData);
            if (resdata && resdata.length > 0) {
                let suma = 0;
                let cont_n = 0;

                resdata.forEach((element: any) => {
                    cont_n = cont_n + 1;
                    suma = suma + element.UserEvaluationStars;
                });
                setUserFeedbacksPromEstrellas(suma / cont_n);
            }

            setUserFeedbacks(resdata);
        } catch (error) {
            console.log(error);
        }
    };

    /* -------------- FIN SOLICITUDES DE DATOS ---------------------------------------------------------------------- */

    /* -------------- USEEFFECT ---------------------------------------------------------------------- */
    useEffect(() => {
        fetchUserStartups(user_id_profile).catch(console.error);
        fetchUserExpertises(user_id_profile).catch(console.error);
        fetchUserPublicData(user_id_profile).catch(console.error);
        fetchUserTags(user_id_profile).catch(console.error);
        fetchUserLanguages(user_id_profile).catch(console.error);
        fetchRoles(user_id_profile).catch(console.error);
        fetchUserFeedbacks(user_id_profile).catch(console.error);
    }, [params]);

    /* ROLES */
    let startups_html = <></>;
    if (esEmprendedor) {
        startups_html = <div className='col-12'>
            <UserProfileExternalStartups user_startups={user_startups} is_loading_user_startups={isLoadingGetUserStartups}></UserProfileExternalStartups>
        </div>;
    }

    let expert_html = <></>;
    if (esExperto) {
        expert_html = <div className='col-12'>
            <UserProfileExternalExperiencias user_expertises={user_expertises} is_loading_user_expertises={isLoadingGetUserExpertises}></UserProfileExternalExperiencias>
        </div>;
    }

    function public_profile_html() {
        let public_profile_html_el = <></>;
        if (isLoadingGetUserStartups || isLoadingGetUserExpertises || isLoadingGetUserPublicData || isLoadingGetUserTags || isLoadingGetUserLanguages || isLoadingGetUserRoles || isLoadingGetUserFeedbacks) {
            let fullscreenClass = "";
            if (isModal == 0) {
                fullscreenClass = "fullscreen";
            }
            public_profile_html_el = <div className={'loading-backdrop ' + fullscreenClass}>
                <h2>
                    <i className="fas fa-spinner fa-spin mr-4"></i>
                    <h2 className='d-inline'>{textPublicProfile.label_loading}</h2>
                </h2>
            </div>;
        } else {
            public_profile_html_el = <div className={'user-profile-container ' + clase_external}>
                <div className='row no-gutters'>
                    <div className='col-12'>
                        <UserProfileExternalTopCard
                            user_feedbacks_prom_estrellas={user_feedbacks_prom_estrellas}
                            user_id_profile={user_id_profile}
                            esEmprendedor={esEmprendedor}
                            esExperto={esExperto}
                            user_expertises={user_expertises}
                            user_donated_times={user_donated_times}
                            isModal={isModal}
                            handleClickCloseDialogPublicProfile={handleClickCloseDialogPublicProfile}
                            user_public_data={user_public_data}
                            is_loading_public_data={isLoadingGetUserPublicData}
                            handleClickDialog={handleClickDialog}
                            isExternal={isExternal}
                            handleAddContact={handleAddContact}
                            handleLoadContactsMain={handleLoadContactsMain}
                            currentUrl={window.location.href}
                            isQRMode={mode === "QR"}
                        ></UserProfileExternalTopCard>
                    </div>
                    <div className='col-12'>
                        <UserProfileExternalIdiomasTags user_languages={user_languages} is_loading_user_startups={isLoadingGetUserStartups} user_tags={user_tags} ></UserProfileExternalIdiomasTags>
                    </div>

                </div>
                <div className='row no-gutters user-profile-startups-experiences-container'>
                    {startups_html}
                    {expert_html}

                </div>
            </div>;
        }
        return (public_profile_html_el);
    }

    /* -------------- TIEMPO DONADO DEL PERFIL ---------------------------------------------------------------------- */
    let GetUserDonatedTime_aux = GetUserDonatedTime(user_id_profile);
    let user_donated_times = GetUserDonatedTime_aux.value;

    /* -------------- DIALOGOS SOLICITAR TIEMPO Y REUNION ---------------------------------------------------------------------- */
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const [sol_reu_open, set_sol_reu_open] = useState(false);
    function handleClickDialog() {
        set_sol_reu_open(!sol_reu_open);
    }

    let navegation_html = <></>;
    if (isModal == 0) {
        navegation_html = <>
            <BottomNav></BottomNav>
        </>;
    }

    return (
        <>
            {navegation_html}
            {public_profile_html()}
            <DialogTiempoReunion
                user_id_profile={user_id_profile} sol_reu_open={sol_reu_open} handleClickDialog={handleClickDialog} textPublicProfile={textPublicProfile}
                QR={mode == "QR"}
            />

            {/* FeedbackDialog */}
            {showFeedbackDialog && (
                <FeedbackDialog
                    open={showFeedbackDialog}
                    onClose={() => setShowFeedbackDialog(false)}
                    onSubmit={handleFeedbackSubmit}  // Aquí conectamos el envío
                    counterpartName={isMentor ? feedbackData.availableTime.menteeName : feedbackData.availableTime.mentorName}
                    isMentor={isMentor}
                />
            )}
        </>
    );
}

export default UserProfileExternal;
