import React, { useState, useEffect } from 'react';
import Tabla from '../../components/Tabla/Tabla';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import QRCode from 'qrcode.react';
import { useGetActivitiesQuery, useGetActiveEventNamesQuery, useGetActiveActivityTypesQuery } from '../../redux/features/events/eventsApiSlice';

type Activity = {
    id: number;
    activityName: string;
    startTime: string;
    endTime?: string;
    duration: number;
    blockDuration: number;
    breakTime?: number | null;
    additionalTime?: number | null;
    description: string;
    visible: number;
    eventId: number;
    industryName: string;
    activityTypeName: string;
    qr: string;
    mentorsCount?: number;
};

type Event = {
    id: number;
    event: string;
};

interface ActivityTableProps {
    handleEdit: (id: number) => void;
    handleDelete: (id: number) => void;
    handleOpenDialog: () => void;
}

const ActivityTable: React.FC<ActivityTableProps> = ({ handleEdit, handleDelete, handleOpenDialog }) => {
    const { data: activities = [], isLoading: isLoadingActivities } = useGetActivitiesQuery({});
    const { data: events = [], isLoading: isLoadingEvents } = useGetActiveEventNamesQuery({});
    const { data: activityTypes = [], isLoading: isLoadingActivityTypes } = useGetActiveActivityTypesQuery({});

    const [tableData, setTableData] = useState<Activity[]>([]);
    const [page, setPage] = useState<number>(0);  // Estado para la página actual
    const [pageSize, setPageSize] = useState<number>(10);  // Estado para el tamaño de la página

    useEffect(() => {
        //const filteredActivities = activities.filter((activity: Activity) => activity.visible === 1);
        
        // Añadimos la fila de añadir solo si no existe ya en la tabla
        const addButtonRow: Activity = {
            id: -1,
            activityName: '',
            startTime: '',
            endTime: '',
            duration: 0,
            blockDuration: 0,
            breakTime: null,
            additionalTime: null,
            description: '',
            visible: 1,
            eventId: 0,
            industryName: '',
            activityTypeName: '',
            qr: '',
            mentorsCount: 0
        };
    
        // Aquí combinamos las actividades filtradas con la fila de "Añadir"
        const newTableData = [...activities, addButtonRow];
    
        // Verificamos que los datos hayan cambiado antes de actualizarlos
        if (JSON.stringify(tableData) !== JSON.stringify(newTableData)) {
            setTableData(newTableData);  // Asignamos los datos en el orden deseado
        }

        console.log(activities);
    }, [activities, tableData]);

    const getEventNameById = (id: number) => {
        const event = events.find((event: Event) => event.id === id);
        return event ? event.event : 'No disponible';
    };

    const columnas: GridColDef[] = [
        {
            headerName: "Evento", field: "eventId", flex: 1, minWidth: 150,
            renderCell: (cellvalues: any) => {
                if (cellvalues.row.id === -1) return null;
                return getEventNameById(cellvalues.value);
            }
        },
        { headerName: "Actividad", field: "activityTypeName", flex: 1, minWidth: 150 },
        { headerName: "Temática", field: "activityName", flex: 1, minWidth: 150 },
        {
            headerName: "Inicio", field: "startTime", flex: 1, minWidth: 130,
            renderCell: (cellvalues: any) => {
                if (cellvalues.row.id === -1) return null;
                if (!cellvalues.value) return null;
                const datetime = moment(cellvalues.value);
                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div>{datetime.format("DD-MM-YYYY")}</div>
                        <div>{datetime.format("HH:mm")}</div>
                    </div>
                );
            }
        },
        {
            headerName: "Fin", field: "endTime", flex: 1, minWidth: 130,
            renderCell: (cellvalues: any) => {
                if (cellvalues.row.id === -1) return null;
                if (!cellvalues.value) return null;
                const datetime = moment(cellvalues.value);
                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div>{datetime.format("DD-MM-YYYY")}</div>
                        <div>{datetime.format("HH:mm")}</div>
                    </div>
                );
            }
        },
        {
            headerName: "Duración Total", field: "duration", flex: 1, minWidth: 150,
            renderCell: (cellvalues: any) => {
                if (cellvalues.row.id === -1) return null;
                return `${cellvalues.value} mins`;
            }
        },
        {
            headerName: "Duración Bloques", field: "blockDuration", flex: 1, minWidth: 200,
            renderCell: (cellvalues: any) => {
                if (cellvalues.row.id === -1) return null;
                return `${cellvalues.value} mins`;
            }
        },
        {
            headerName: "Descanso", field: "breakTime", flex: 1, minWidth: 150,
            renderCell: (cellvalues: any) => {
                if (cellvalues.row.id === -1) return null;
                return cellvalues.value !== null ? `${cellvalues.value} mins` : 'N/A';
            }
        },
        {
            headerName: "Adición", field: "additionalTime", flex: 1, minWidth: 150,
            renderCell: (cellvalues: any) => {
                if (cellvalues.row.id === -1) return null;
                return cellvalues.value !== null ? `${cellvalues.value} mins` : 'N/A';
            }
        },
        {
            headerName: "Slots", field: "mentorsCount", flex: 1, minWidth: 150,
            renderCell: (cellvalues: any) => {
                if (cellvalues.row.id === -1) return null;
                return `${cellvalues.value}`;
            }
        },
        { headerName: "Descripción", field: "description", flex: 2, minWidth: 250 },
        {
            headerName: "QR", field: "qr", flex: 1, minWidth: 100,
            renderCell: (cellvalues: any) => {
                if (cellvalues.row.id === -1) return null;
                const qrValue = `https://etmmeet.org/events/${cellvalues.value}?mode=QR`;
                return (
                    <div className='d-flex justify-content-center'>
                        <QRCode value={qrValue} size={50} />
                    </div>
                );
            }
        },
        {
            headerName: "Opciones", field: "opciones", flex: 1, minWidth: 120, maxWidth: 150,
            renderCell: (cellvalues: any) => {
                const isLast = cellvalues.row.id === -1;
                if (isLast) {
                    return (
                        <div className='d-flex flex-column justify-content-center align-items-stretch w-100' style={{ paddingTop: '10px', paddingBottom: '15px' }}>
                            <button
                                className="btn btn-primary btn-block p-1"
                                onClick={handleOpenDialog}
                            >
                                Añadir
                            </button>
                        </div>
                    );
                } else {
                    return (
                        <div className='d-flex flex-column justify-content-center align-items-stretch w-100'>
                            <button className="btn btn-secondary btn-block p-1" onClick={() => handleEdit(cellvalues.row.id)}>Modificar</button>
                            <button className="btn btn-danger btn-block p-1" onClick={() => handleDelete(cellvalues.row.id)}>Eliminar</button>
                        </div>
                    );
                }
            }
        }
    ];

    return isLoadingActivities || isLoadingEvents || isLoadingActivityTypes ? (
        <div className='table-container-loading'>
            <p>Cargando actividades...</p>
        </div>
    ) : (
        <div className='table-container'>
            <Tabla
                columnas={columnas}
                datos={tableData}
                pageSize={pageSize}
                setPageSize={setPageSize}
                page={page}
                onPageChange={setPage}
                getRowId={(row: Activity) => row.id}
            />
        </div>
    );
};

export default ActivityTable;
