import { useEffect, useState } from 'react';
/* variables tabla */
import Tabla from '../../../components/Tabla/Tabla';
import { GridColDef } from '@mui/x-data-grid';
//import { usuario_html, startup_html } from '../../../utils/meetings';
import { usuario_html } from '../../../utils/meetings';
//import { UsuarioReunion, Startup, Reunion, TiempoDonado, UsuarioSolicitud, Expertiz, Solicitud } from "../../../interfaces/meetings"
import { personalData } from '../../../interfaces/dimentions';
import { useSelector } from 'react-redux';
import { selectPersonalData } from '../../../redux/features/user/userSlice';
import { usePostGetUsersInfoMutation } from '../../../redux/features/user/userApiSlice';
import "./MeetingsSolicitudes.scss";
 
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { selectUserSolicitudesRecibidas, setUserSolicitudesRecibidas, setUserSolicitudesDirectas, selectUserSolicitudesDirectas } from '../../../redux/features/meetings/meetingsSlice';
import { useGetUserSolicitudesRecibidasMutation, usePostUserRespuestaSolicitudRecibidaMutation, usePostCreateZoomMeetingMutation, useGetUserDirectAvailableTimesMutation } from '../../../redux/features/meetings/meetingsApiSlice';
import { useDispatch } from 'react-redux';
import { addAlert, deleteAlert } from '../../../redux/features/alerts/alertsSlice';
import { changeDateTimeZone, changeDonatedTimeZone } from '../../../utils/utilsForms';
import GetActualTimeZone from '../../../hooks/utils/GetActualTimeZone/GetActualTimeZone';
import moment from "moment"
import { selectCurrentDictonary } from '../../../redux/features/language/LanguageSlice';

// New 
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import { usePostUserCrearSolicitudMutation, usePostDeleteDirectDonationsMutation, usePostCreateNotificationMutation } from '../../../redux/features/meetings/meetingsApiSlice';



const MeetingsSolicitudesRecibidas = ({ handleOpenDetalleSolicitud }: any) => {
    //NEW
    const [postUserCrearSolicitud, { isLoadingPostUserCrearSolicitud }]: any = usePostUserCrearSolicitudMutation()
    const [DeleteDirecDonations, { isLoadingDirectDonations }]: any = usePostDeleteDirectDonationsMutation()
    const [isAcceptClicked, setIsAcceptClicked] = useState(false);
    const [createNotification, {isLoadingCreateNotification}]: any = usePostCreateNotificationMutation();
    const [directDonations, setdirectDonations] = useState<any | null>(null)
    const [directDonationsNames, setdirectDonationsNames] = useState<any[]>([])

    //nuevo modal para confirmacion
    const [openAcceptConfirmation, setOpenAcceptConfirmation] = useState(false);
    const handleOpenAcceptConfirmation = (id:any) => {
         
        setSelectedRequestId(id);
        setOpenAcceptConfirmation(true);
    };
    // nuevo modal para el rechazo
    const [openRejectConfirmation, setOpenRejectConfirmation] = useState(false);
  
    // nuevo modal aceptar dentro del otro modal

    // Estado para controlar la visibilidad del modal de confirmación
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    // Estado para almacenar el ID temporalmente para la confirmación
    const [tempMeetingId, setTempMeetingId] = useState(null);
    const handleOpenConfirmModal = (id:any) => {
    setTempMeetingId(id);
    setOpenConfirmModal(true);
};
const handleConfirmMeeting = () => {
    createDirectMeetingRequest(tempMeetingId, user_id, selectedRowData.UserId);
    handleCloseModal();
    setOpenConfirmModal(false); // Cerrar el modal de confirmación
};

// Nuevo modal para rechazar donación directa
const [openRejectDirectDonation, setOpenRejectDirectDonation] = useState(false);
const [rowtoDelete, setRowtoDelete] = useState<any[]>([])
const handleOpenRejectDirectDonation = (row:any) => {
    setRowtoDelete(row); // Asumiendo que ya tienes una variable de estado para almacenar el ID temporal
    setOpenRejectDirectDonation(true);
};
const handleConfirmRejectDirectDonation = () => {
    handleEliminarDonacionDirecta(rowtoDelete, 1); // Asumiendo que esta función maneja la lógica de rechazo
    setOpenRejectDirectDonation(false); // Cierra el modal después de la acción
};

    // Añadir después de la declaración de tus hooks de estado
    const [pageSize, setPageSize] = useState<number>(10); // Estado para el tamaño de página
    const [page, setPage] = useState<number>(0); // Estado para la página actual



    const textPublicProfile = useSelector(selectCurrentDictonary).public_profile
    const user_personal_data: personalData = useSelector(selectPersonalData) //acceder a datos del usuario logueado, esto lo cambiamos de lugar iba dps de const_actual_Time zone, estas 2 lineas
    let user_id = user_personal_data.Id
    const [getDirectDonations, isLoadingDirect]: any = useGetUserDirectAvailableTimesMutation()
    const [getNames, isLoadingNames]: any = usePostGetUsersInfoMutation()
    const directDonationsData = async () => {
        const directdonations: any = await getDirectDonations({ userId: user_id })
        const resdirectdonations = JSON.parse(directdonations.data.responseData)


        //  console.log(resdirectdonations)
        //  console.log('user_id'+ user_id)
        setdirectDonations(resdirectdonations)
        //   console.log("directdonationsactualizado")
        //   console.log(directDonations)

    }
    // const fetchUserInfo = async (userIdValues: any) => {
    
 
    //     console.log(directDonationsNames)
    //     const nameDonations: any = await getNames(ids)
    //     const resnameDonations = JSON.parse(nameDonations.data.responseData)
  
    //     console.log(resnameDonations)

    // }
    // useEffect(() => {
    //     fetchUserInfo(1)
    //   }, [directDonationsNames]);


    useEffect(() => {
        handleReload2()
    }, []);


    //END NEW
    const actual_time_zone = GetActualTimeZone()

    // NUEVOS MODALES
    const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
    const [openRejectDialog, setOpenRejectDialog] = useState(false);
    const [selectedRequestId, setSelectedRequestId] = useState(null);
    const handleOpenAcceptDialog = (id:any) => {
        setSelectedRequestId(id);
        setOpenAcceptDialog(true);
    };
    
    const handleOpenRejectDialog = (id:any) => {
        

        setSelectedRequestId(id);
        setOpenRejectDialog(true);
    };

    const handleAccept = () => {
         
        if (selectedRequestId !== null) {
            handleClickResponderSolicitud(selectedRequestId, 2);
            setOpenAcceptConfirmation(false)
        }
    };
    
    const handleReject = () => {
        if (selectedRequestId !== null) {
            handleClickResponderSolicitud(selectedRequestId, 1);
            setOpenRejectDialog(false);
        }
    };
 
    

    const user_solicitudes_recibidas: any = useSelector(selectUserSolicitudesRecibidas) //acceder a datos del usuario logueado
    const user_solicitudes_directas: any = useSelector(selectUserSolicitudesDirectas)

    const [getSoliciudesRecibidas, { isLoadingGet }]: any = useGetUserSolicitudesRecibidasMutation()
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const textMeetings = useSelector(selectCurrentDictonary).meetings
    const [idGuardando, setIdGuardando] = useState<number | null>(null)

    useEffect(() => {
        if (user_id) {
            handleReload()
        }
    }, [user_personal_data]);

    async function handleReload() {
        // console.log("USER ID",user_id)
        setIsLoading(true)
        const res: any = await getSoliciudesRecibidas({ user_id: user_id });
        const resData = JSON.parse(res.data.responseData)
        dispatch(setUserSolicitudesRecibidas({ user_solicitudes_recibidas: resData }))
        setIsLoading(false)
    }
    async function handleReload2() {
        // console.log("USER ID 2", user_id)
        const directdonations: any = await getDirectDonations({ userId: user_id })
        const resdirectdonations = JSON.parse(directdonations.data.responseData)
        //console.log(" DIRECT DONATIONS", resdirectdonations)
        // console.log("DIRECT DONATIONS 2", resdirectdonations)
        // console.log("mostrando estado actualizado dps")
        // if (directDonations) {
        //     directDonations.forEach((donation) => {
        //       datos.push({
        //         // Asumiendo que tus nombres de campo deben coincidir con los de las columnas definidas
        //         CreatedAd: moment(donation.CreatedAt).format("DD-MM-YYYY HH:mm"), // Formateando la fecha

        //       });
        //     });
        //   }
        if (resdirectdonations) {
            // console.log("mostrando elements directdonations")
            for (let i = 0; i < resdirectdonations.length; i++) {
                const element = resdirectdonations[i]
                // console.log("ESTE", element)

                //  datos.push({
                //     CellType: "two",
                //     id: element.Id

                // })

            }
            const groupedByUserId = resdirectdonations.reduce((acc: any, cur: any) => {
                // Buscar si ya existe un grupo para este UserId
                const existingGroup = acc.find((group: any) => group.UserId === cur.UserId);

                if (existingGroup) {
                    // Si ya existe, solo añadir la entrada actual al arreglo de additionalEntries
                    existingGroup.additionalEntries.push({
                        Id: cur.Id,
                        Date: cur.Date,
                        StartTime: cur.StartTime,
                        EndTime: cur.EndTime,
                        UserId: cur.UserId,
                        CreatedAt: cur.CreatedAt,
                        UserRequestName: cur.UserName,
                        UserRequestLastName: cur.UserLastName,
                        UserRequestProfilePicture: cur.UserProfilePicture,
                        UserRequestRoles: cur.UserRequestRoles
                        //   let user_id = row.UserRequestId
                        //             let nombre = row.UserRequestName
                        //             let apellido = row.UserRequestLastName
                        //             let url_foto = row.UserRequestProfilePicture
                        //             let roles = row.UserRequestRoles
                    });
                } else {
                    // Si no existe, crear un nuevo grupo con el UserId, UserName, UserLastName y UserProfilePicture en el nivel superior
                    acc.push({
                        UserId: cur.UserId,
                        UserName: cur.UserName, // Asumiendo que UserName ya viene en el objeto cur
                        UserLastName: cur.UserLastName, // Asumiendo que UserLastName ya viene en el objeto cur
                        UserProfilePicture: cur.UserProfilePicture, // Asumiendo que UserProfilePicture ya viene en el objeto cur
                        additionalEntries: [{
                            Id: cur.Id,
                            Date: cur.Date,
                            StartTime: cur.StartTime,
                            EndTime: cur.EndTime,
                            UserId: cur.UserId,
                            CreatedAt: cur.CreatedAt,
                            UserRequestName: cur.UserName,
                            UserRequestLastName: cur.UserLastName,
                            UserRequestProfilePicture: cur.UserProfilePicture,
                            UserRequestRoles: cur.UserRequestRoles
                        }]
                    });
                }

                return acc;
            }, []);
            dispatch(setUserSolicitudesDirectas({ user_solicitudes_directas: groupedByUserId })) // modify


            // console.log("USER SOLICITUDES DIRECTAS SELECTOR", user_solicitudes_directas)

        }

        // console.log("GroupedBYUserId");
        // console.log(groupedByUserId);
        // console.log(directDonations)
        // if(groupedByUserId){
        //     console.log("EXISTE GROUPED y su longitud es: " + groupedByUserId.length)
        //     for (let i = 0; i < groupedByUserId.length; i++) {
        //         const element = groupedByUserId[i];
        //         console.log("mostrando elementos finales")
        //         console.log(element)

        //              datos.push({
        //                 CellType: "two",
        //                 id: i,
        //                 UserId: element.UserId,
        //                 CreatedAd: element.additionalEntries[0].CreatedAt,
        //                 additionalEntries: element.additionalEntries,
        //                 UserRequestName: element.UserName,
        //                 UserRequestLastName: element.UserLastName,
        //                 UserRequestProfilePicture: element.UserProfilePicture,
        //                 UserRequestRoles: element.UserRequestRoles


        //             })

        //     }
        // }

       
        //   const userIdValues = groupedByUserId.map(item => item.UserId);
        //   console.log("Solo UserIds:");
        //   console.log(userIdValues);
        // setdirectDonationsNames(userIdValues)
        //   console.log("ACTUALIZACION!")
        //   console.log(directDonationsNames)



    }

    let datos: any = []
    if (user_solicitudes_recibidas) {
        for (let i = 0; i < user_solicitudes_recibidas.length; i++) {
            const element = user_solicitudes_recibidas[i];
            if (!(element.RequestStatusId == 2 || element.RequestStatusId == 1 || element.RequestStatusId == 3)) {

                datos.push({
                    ConfirmDateTime: element.ConfirmDateTime,
                    CreatedAd: element.CreatedAd,
                    id: element.Id,
                    MeetingTypeId: element.MeetingTypeId,
                    MeetingTypeName: element.MeetingTypeName,
                    RequestStatusId: element.RequestStatusId,

                    UserRequestId: element.UserRequestId,
                    UserRequestLastName: element.UserRequestLastName,
                    UserRequestName: element.UserRequestName,
                    UserRequestProfilePicture: element.UserRequestProfilePicture,
                    UserRequestRoles: element.UserRequestRoles,

                    UserRequestedId: element.UserRequestedId,
                    UserRequestedLastName: element.UserRequestedLastName,
                    UserRequestedName: element.UserRequestedName,
                    UserRequestedProfilePicture: element.UserRequestedProfilePicture,
                    UserRequestedRoles: element.UserRequestedRoles,

                    UserRequestedTimeId: element.UserRequestedTimeId, // FALTAN DATOS DE LA HORA DONADA

                    RequestStatusReason: element.RequestStatusReason,
                    UserRequestedEndTime: element.UserRequestedEndTime,
                    UserRequestedStartTime: element.UserRequestedStartTime,
                    UserRequestedTimeZoneOffset: element.UserRequestedTimeZoneOffset,
                    CellType: "one"
                })
            }
        }

    }
    if (user_solicitudes_directas) {
        // console.log("EXISTE selector user solicitudes directas y su longitud es: " + user_solicitudes_directas.length)
        for (let i = 0; i < user_solicitudes_directas.length; i++) {
            const element = user_solicitudes_directas[i];
            // console.log("mostrando elementos finales")
            // console.log(element)

            datos.push({
                CellType: "two",
                id: i,
                UserId: element.UserId,
                CreatedAd: element.additionalEntries[0].CreatedAt,
                additionalEntries: element.additionalEntries,
                UserRequestName: element.UserName,
                UserRequestLastName: element.UserLastName,
                UserRequestProfilePicture: element.UserProfilePicture,
                UserRequestRoles: element.UserRequestRoles


            })

        }
    }




    const [postUserResponderSolicitud, { isLoadingPostUserResponderSolicitud }]: any = usePostUserRespuestaSolicitudRecibidaMutation()

    const borrarSolicitud = (id: number) => {

        const newSolicitudes = user_solicitudes_recibidas.filter((solicitud: any) => solicitud.Id !== id)
        dispatch(setUserSolicitudesRecibidas({ user_solicitudes_recibidas: newSolicitudes }))

    }

    async function handleClickResponderSolicitud(id_solicitud: number, status: number) {
        setIdGuardando(id_solicitud)
        const randomId = Math.floor(Math.random() * 90000) + 10000
        let new_alert = { id: "aceptando_solicitud" + randomId, type: "loading", title: textMeetings.param_1, desc: "", close: false }
        dispatch(addAlert({ alert: new_alert }))

        try {
            let item = {
                "meetingRequestId": id_solicitud,
                "meetingRequestStatusId": status,
                "reason": ""
            }
            await postUserResponderSolicitud({ body: item });

            dispatch(deleteAlert({ alert_id: "aceptando_solicitud" + randomId }));
            let new_alert2 = { type: "success", title: textMeetings.param_2, desc: "", timeout: 2000, close: false }
            dispatch(addAlert({ alert: new_alert2 }))

        } catch (error) {
            dispatch(deleteAlert({ alert_id: "aceptando_solicitud" + randomId }));
            let new_alert2 = { type: "error", title: textMeetings.param_3, desc: "", timeout: 2000, close: false }
            dispatch(addAlert({ alert: new_alert2 }))
        }

        borrarSolicitud(id_solicitud)
    }
    const buttonDisabled = (id: number) => {

        if (id === idGuardando) return true

        return false
    }
    function opciones_html(id_solicitud: number, solicitud: any, RequestStatusId: number) {
        let btn_cancelar_html = <></>
        if (RequestStatusId != 1 && RequestStatusId != 2 && RequestStatusId != 3) { // si esta rechazada o cancelada no se puede rechazar la solicitud
            btn_cancelar_html = <>
                <button disabled={buttonDisabled(id_solicitud)} className="btn btn-primary btn-block p-1" onClick={() => handleOpenAcceptConfirmation(id_solicitud)}>
                     {textMeetings.btn_aceptar}
                </button>
                <button disabled={buttonDisabled(id_solicitud)} className="btn btn-danger btn-block p-1" onClick={() => handleOpenRejectDialog(id_solicitud)}>
                    {textMeetings.btn_rechazar}
                </button>
            </>
        }
        return (
            <div className='d-flex flex-column justify-content-center align-items-center'>
                {btn_cancelar_html}
                <button className="btn btn-outline-dark btn-block p-1" onClick={() => handleOpenDetalleSolicitud(0, id_solicitud, solicitud)}>
                    {textMeetings.th_opciones}
                </button>
            </div>
        )
    }

    /* TABLA TIEMPOS DONADOS */

    let columnas: GridColDef[] = [
        {
            headerName: textMeetings.table_1.header_1,
            description: textMeetings.table_1.header_1,
            field: "CreatedAd",
            flex: 1,
            minWidth: 130,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let type = row.CellType
                let CreatedAd = row.CreatedAd
                let dict_createdAd = changeDateTimeZone(actual_time_zone, CreatedAd)

                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <div>
                                {moment(dict_createdAd.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
                            </div>
                            <div>
                                {dict_createdAd.time}

                            </div>
                        </div>
                    </div>
                )

            }
        },
        {
            headerName: textMeetings.table_1.header_2, description: textMeetings.table_1.header_2, field: "HoraReunion", flex: 1, minWidth: 130,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let type = row.CellType
                if (type === 'one') {


                    let UserRequestedStartTime = row.UserRequestedStartTime
                    let UserRequestedEndTime = row.UserRequestedEndTime
                    let fecha_inicio = UserRequestedStartTime.split("T")[0]
                    let hora_inicio = UserRequestedStartTime.split("T")[1].split(":")[0] + ":" + UserRequestedStartTime.split("T")[1].split(":")[1]
                    let hora_termino = UserRequestedEndTime.split("T")[1].split(":")[0] + ":" + UserRequestedEndTime.split("T")[1].split(":")[1]

                    let dict = changeDonatedTimeZone(actual_time_zone, fecha_inicio, hora_inicio, hora_termino)

                    return (
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                <div>
                                    {moment(dict.date, "YYYY-MM-DD").format("DD-MM-YYYY")}
                                </div>
                                <div>
                                    {dict.startTime + " - " + dict.endTime}
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div>{textMeetings.por_confirmar}</div>
                    )
                }
            }
        },
        {
            headerName: textMeetings.enviada_por, description: textMeetings.table_1.header_3, field: "UserRequestId",
            flex: 1, minWidth: 200,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let type = row.CellType
                let user_id = row.UserRequestId
                let nombre = row.UserRequestName
                let apellido = row.UserRequestLastName
                let url_foto = row.UserRequestProfilePicture
                let roles = row.UserRequestRoles
                return (
                    <div className='d-flex flex justify-content-center align-items-center w-100'>
                        {usuario_html(user_id, nombre, apellido, url_foto, roles)}
                    </div>
                )
            }
        },
        {
            headerName: textMeetings.table_1.header_4, description: textMeetings.table_1.header_4, field: "MeetingTypeName", flex: 2, minWidth: 230,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let type = row.CellType
                if (type === 'one') {
                    return (
                        <div className='contenedor-tipo-solicitud'>
                            <p className='tipo-solicitud'>{textMeetings.type_meetings[row.MeetingTypeId]}</p>
                        </div>
                    )
                } else {
                    return (
                        <div>{textMeetings.tipo_donacion_directa}</div>
                    )
                }
            }
        },
        {
            headerName: textMeetings.table_1.header_5, description: textMeetings.table_1.header_5, field: "RequestStatusId", flex: 1, minWidth: 180,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let type = row.CellType
                if (type === 'one') {
                    let RequestStatusId = row.RequestStatusId
                    let request_status = textMeetings.alerta_1
                    let request_status_html_class = ""
                    if (RequestStatusId == 2) {
                        request_status = textMeetings.alerta_2
                        request_status_html_class = "text-success"
                    }
                    if (RequestStatusId == 1) {
                        request_status = textMeetings.alerta_3
                        request_status_html_class = "text-danger"
                    }
                    if (RequestStatusId == 3) {
                        request_status = textMeetings.alerta_4
                        request_status_html_class = "text-danger"
                    }
                    let request_status_html = <div className={request_status_html_class}>
                        {request_status}
                    </div>
                    return (request_status_html)
                } else {
                    return (
                        <div>{textMeetings.esperando_respuesta}</div>
                    )
                }
            }
        },
        {
            headerName: textMeetings.table_1.header_6, description: textMeetings.table_1.header_6, field: "Opciones_html", sortable: false, flex: 1, minWidth: 120,
            renderCell: (cellvalues) => {
                let row = cellvalues.row
                let type = row.CellType
                if (type === 'one') {
                    let id = row.id
                    let RequestStatusId = row.RequestStatusId
                    return (
                        <>
                            {opciones_html(id, row, RequestStatusId)}
                        </>
                    )
                } else {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }} >
                            <button
                                className="btn btn-primary flex-grow p-1" onClick={() => handleVerDetalles(row)}

                            // Cambio aquí para pasar la fila completa  clase previa: btn-outline-dark
                            >
                                {textMeetings.modal_4_detalles}
                            </button>
                            <button
    className="btn btn-danger flex-grow-1 p-1"
    onClick={() => handleOpenRejectDirectDonation(row)}
>
    {textMeetings.modal_5_borrar}
</button>
                        </div>

                    )
                }
            }
        },
    ]

    const [openModal, setOpenModal] = useState(false);
    // Estado para almacenar los detalles de la solicitud seleccionada
    const [selectedRowData, setSelectedRowData] = useState<any>(null);
    useEffect(() => {
        if (selectedRowData) {
            // console.log("Datos de la fila seleccionada: ", selectedRowData);
        }
    }, [selectedRowData]);
    const handleVerDetalles = (rowData: any) => {
        setSelectedRowData(rowData);
        setOpenModal(true);
    };

    const handleEliminarDonacionDirecta = async (row: any, notify: any) => {



        let item = {
            "UserId": user_id,
            "UserIdTo": row.UserId
        }
        if(notify == 1){

            let new_alert4 = {
                id: "donacion_directa_eliminar",
                type: "loading",
                title: textMeetings.cargando,
                desc: textMeetings.rechazando_donacion_directa,
                timeout: 3000,
                close: false,
              };
            dispatch(addAlert({ alert: new_alert4 }));
            dispatch(deleteAlert({ alert_id: "donacion_directa_eliminar" }));
            let new_alert2 = { id: "eliminando_donacion_directa", type: "success", title: textMeetings.donacion_directa_rechazada_exitosamente, timeout: 2000, desc: " ", close: true }
            dispatch(addAlert({ alert: new_alert2 }));  
        }
      
       
        // console.log("hola", row)
        // console.log("My id: ", user_id)
        // console.log("ID To", row.UserId)
        // console.log("Payload", item)
        const responsee = await DeleteDirecDonations({ body: item });
      
        if(notify == 1){
        let body = {
            UserId:row.UserId,
            UserVinculated: user_id,
            NotificationTypeId: 14
        }
        await createNotification ({ body : body}) }
        const responseDataa = responsee.data;  

        // console.log(" ELIMINANDO !Respuesta de la API:", responseDataa);


        // NEWWW
        const directdonations: any = await getDirectDonations({ userId: user_id })
        // console.log(directdonations.data.statusCode)
        let responsedara
        if (directdonations.data.statusCode != 500){
            responsedara = JSON.parse(directdonations.data.responseData)
        } else{
            responsedara = null
        }
        const resdirectdonations = responsedara
        if (resdirectdonations) {
            // console.log("resdirectdonationsDELETE", resdirectdonations)
            // console.log("mostrando elements directdonationsDELETED!")
            for (let i = 0; i < resdirectdonations.length; i++) {
                const element = resdirectdonations[i]
                // console.log("ESTEDELETED!", element)

                //  datos.push({
                //     CellType: "two",
                //     id: element.Id

                // })

            }
            const groupedByUserId = resdirectdonations.reduce((acc: any, cur: any) => {
                // Buscar si ya existe un grupo para este UserId
                const existingGroup = acc.find((group: any) => group.UserId === cur.UserId);

                if (existingGroup) {
                    // Si ya existe, solo añadir la entrada actual al arreglo de additionalEntries
                    existingGroup.additionalEntries.push({
                        Id: cur.Id,
                        Date: cur.Date,
                        StartTime: cur.StartTime,
                        EndTime: cur.EndTime,
                        UserId: cur.UserId,
                        CreatedAt: cur.CreatedAt,
                        UserRequestName: cur.UserName,
                        UserRequestLastName: cur.UserLastName,
                        UserRequestProfilePicture: cur.UserProfilePicture,
                        UserRequestRoles: cur.UserRequestRoles
                        //   let user_id = row.UserRequestId
                        //             let nombre = row.UserRequestName
                        //             let apellido = row.UserRequestLastName
                        //             let url_foto = row.UserRequestProfilePicture
                        //             let roles = row.UserRequestRoles
                    });
                } else {
                    // Si no existe, crear un nuevo grupo con el UserId, UserName, UserLastName y UserProfilePicture en el nivel superior
                    acc.push({
                        UserId: cur.UserId,
                        UserName: cur.UserName, // Asumiendo que UserName ya viene en el objeto cur
                        UserLastName: cur.UserLastName, // Asumiendo que UserLastName ya viene en el objeto cur
                        UserProfilePicture: cur.UserProfilePicture, // Asumiendo que UserProfilePicture ya viene en el objeto cur
                        additionalEntries: [{
                            Id: cur.Id,
                            Date: cur.Date,
                            StartTime: cur.StartTime,
                            EndTime: cur.EndTime,
                            UserId: cur.UserId,
                            CreatedAt: cur.CreatedAt,
                            UserRequestName: cur.UserName,
                            UserRequestLastName: cur.UserLastName,
                            UserRequestProfilePicture: cur.UserProfilePicture,
                            UserRequestRoles: cur.UserRequestRoles
                        }]
                    });
                }

                return acc;
            }, []);
            //dispatch(setUserSolicitudesDirectas([]))
             dispatch(setUserSolicitudesDirectas({ user_solicitudes_directas: groupedByUserId })) // modify
            // console.log("USER SOLICITUDES DIRECTAS SELECTOR ELIMINATED!", user_solicitudes_directas)

        }else{
            // console.log("NO HAY USER SOLICITUDES, SE BUSCA DEJAR NULL USER SOLICITUDES")
            dispatch(setUserSolicitudesDirectas([]))
        }

        //     console.log("DATOS PRE FILTER",datos) // Acá quizás hacer un filter y mandar un handlereload para actualizar la tabla. ESTUDIAR DISPATCH Y HACER ANALOGO AL DE AHORA
        //     const userRequestedIdToRemove = 18569
        //     datos = datos.filter((item: any) =>
        //   !(item.CellType === "two" && item.UserId === userRequestedIdToRemove)
        // );
        //     console.log("DATOS POST FILTER", datos)
        //     handleReload()

    }
    const handleCloseModal = () => {
        setSelectedRowData(null);
        setOpenModal(false);
        setIsAcceptClicked(false);  //Reiniciamos el boton, en caso de que se queiran aceptar otras solicitudes directas...
    };
    const handleAcceptClick = () => {
        // console.log("Selected Row Data:", selectedRowData);
    };
    const createDirectMeetingRequest = async (timeId: any, userId: any, userRequestedId: any) => {
        if (isAcceptClicked) return;
        setIsAcceptClicked(true);
        // console.log("meetingReason: " + "Donación directa");
        // console.log("meetingTimeId: " + timeId);
        // console.log("meetingTypeId: " + 1);
        // console.log("userRequestId: " + userId);
        // console.log("UserRequestedId: " + userRequestedId);

        let item = {
            "userRequestedId": userRequestedId,
            "userRequestId": user_id,
            "meetingTypeId": 5, // por mientras, lo dejamos como "busco ofrecer mis servicios"
            "meetingTimeId": timeId,
            "meetingReason": "Donacion directa",
        };

        // console.log("PAYLOAD A CREATETIMEREQUEST ASYNC!");
        // console.log(item);
        try {
            if(user_id == 0){
                throw new Error("Error de ids");
            }
            let new_alert = {
                id: "donacion_directa_step1",
                type: "loading",
                title: textMeetings.cargando,
                desc: textMeetings.aceptando_donacion_directa,
                timeout: 2000,
                close: false,
              };
              dispatch(addAlert({ alert: new_alert }));

            const response = await postUserCrearSolicitud({ body: item });
            if(response.data.responseMessage === 'Error de UserRequestId'){
                throw new Error("Error de ids")
            }
            const responseData = response.data; // Asume que la respuesta está en response.data
            dispatch(deleteAlert({ alert_id: "donacion_directa_step1" }));

            // console.log("Respuesta de la API:", responseData);

            // Acceder al ID de la reunión
            let meetingId;
            if (responseData && responseData.responseData) {
                const meetingResponse = JSON.parse(responseData.responseData);
                const meetingRequestId = meetingResponse.MeetingRequestId;
                meetingId = meetingRequestId;

                // console.log("ID de la reunión:", meetingRequestId);
                let itemConfirm = {
                    "meetingRequestId": meetingRequestId,
                    "meetingRequestStatusId": 2,
                    "reason": "",
                    "IsDirect": 1 // Si es igual a 1 se toma como donación directa,y no se añaden modificaciones y se busca actualizar los correos enviados (esto falta...)
                }
                let new_alert2 = {
                    id: "donacion_directa_step2",
                    type: "loading",
                    title: textMeetings.cargando,
                    desc: textMeetings.agendando_reunion,
                    timeout: 2000,
                    close: false,
                  };
                  dispatch(addAlert({ alert: new_alert2 }));
                await postUserResponderSolicitud({ body: itemConfirm });
                let row = {
                    "UserId": userRequestedId
                }
                await handleEliminarDonacionDirecta(row,0)

            }
            dispatch(deleteAlert({ alert_id: "donacion_directa_step2" }));
            let new_alert2 = { id: "guardando_tiempos_donados_correctamente", type: "success", title: textMeetings.reunion_agendada_exitosamente, timeout: 1000, desc: " ", close: true }
              dispatch(addAlert({ alert: new_alert2 }));
            //   console.log("Meeting id to send!", meetingId)
              let body = {
                UserId:userRequestedId,
                UserVinculated: user_id,
                NotificationTypeId: 13,
                MeetingIdto: meetingId // con este meetingid tenemos que modificar la lambda function para obtener el tiempo donado. "por eso el to"
            }
            await createNotification ({ body : body})
              // ACÁ AÑADIR NOTIFICATION DE DONACION DIRECTA ACEPTADA.
        } catch (error) {
            // console.error("Error al crear la solicitud de reunión:", error);
            let new_alert2 = { type: "error", title: textPublicProfile.param_3, desc: textPublicProfile.error0, close: true }
            dispatch(addAlert({ alert: new_alert2 }))
            
        }
    };


    let table_html = <></>
    if (isLoading) {
        table_html = <div className='table-container-loading'>
            <p>{textMeetings.loading_table}</p>
        </div>
    } else {
        table_html = <div className='table-container'>
            <Tabla 
  columnas={columnas} 
  datos={datos} 
  pageSize={pageSize}        // Añadir propiedad pageSize
  setPageSize={setPageSize}  // Añadir propiedad setPageSize
  page={page}                // Añadir propiedad page
  onPageChange={setPage}     // Añadir propiedad onPageChange
/>

        </div>
    }

    const acceptConfirmationDialog = (
        <Dialog open={openAcceptConfirmation} onClose={() => setOpenAcceptConfirmation(false)}>
            <DialogTitle>{textMeetings.confirmacion}</DialogTitle>
            <DialogContent>
                <p>{textMeetings.modal_solicitudes_por_responder_aceptar}</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAccept} color="primary">
                    {textMeetings.positivo}
                </Button>
                <Button onClick={() => setOpenAcceptConfirmation(false)} color="primary">
                    {textMeetings.negativo}
                </Button>
            </DialogActions>
        </Dialog>
    );
    const rejectConfirmationDialog = (
        <Dialog open={openRejectDialog} onClose={() => setOpenRejectDialog(false)}>
            <DialogTitle>{textMeetings.confirmacion}</DialogTitle>
            <DialogContent>
                <p>{textMeetings.modal_solicitudes_por_responder_rechazar}</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleReject} color="primary">
                    {textMeetings.positivo}
                </Button>
                <Button onClick={() => setOpenRejectDialog(false)} color="primary">
                    {textMeetings.negativo}
                </Button>
            </DialogActions>
        </Dialog>
    );
    
    return (
        <div className='row mt-4'>
            <div className='col-12 mb-4'>
                <div className='d-flex justify-content-start align-items-center mb-2'>

                    <h3 className='mb-0 mr-2 font-italic'><b>{textMeetings.titulo_2}</b></h3>
                    <div className="btn-refresh mt-1" onClick={handleReload}>
                        <AutorenewIcon fontSize='small' />
                    </div>
                </div>
                <p>
                    {textMeetings.desc_2}
                </p>
            </div>
            <div className='col-12 mb-4'>
                {table_html}

            </div>

            <Dialog open={openModal} onClose={handleCloseModal}>
    <DialogTitle>{textMeetings.horas_directas_donadas}</DialogTitle>
    <div style={{ fontSize: 'medium', color: '#505050', textAlign: 'center', marginTop: '.10px' }}>
        {textMeetings.seleccione_solo_una}
    </div>
    <DialogContent>
        {selectedRowData && (
            <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th className="fs-5 text-center" style={{ border: 'none' }}>{textMeetings.modal_dia}</th>
                            <th className="fs-5 me-3 text-center" style={{ border: 'none' }}>{textMeetings.modal_hora}</th>
                            <th className="fs-5 me-3 text-center" style={{ border: 'none' }}>{textMeetings.modal_opcion}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(() => {
                            
                            const sortedEntries = [...selectedRowData.additionalEntries].sort((a: any, b: any) => {
                                const startTimeA = new Date(a.StartTime);
                                const startTimeB = new Date(b.StartTime);
                                return startTimeA.getTime() - startTimeB.getTime();
                            });
 
                            return sortedEntries.map((entry: any, index: number) => {
                                const fechaInicio = entry.StartTime ? entry.StartTime.split("T")[0] : null;
                                const horaInicio = entry.StartTime.split("T")[1].split(":")[0] + ":" + entry.StartTime.split("T")[1].split(":")[1];
                                const horaFin = entry.EndTime.split("T")[1].split(":")[0] + ":" + entry.EndTime.split("T")[1].split(":")[1];
                                let dateStart = changeDateTimeZone(actual_time_zone, entry.StartTime);
                                let dict = changeDonatedTimeZone(actual_time_zone, fechaInicio, horaInicio, horaFin);
                                const tid = entry.Id;

                                return (
                                    <tr key={index}>
                                        <td className="align-middle fs-5">
                                            {dateStart ? moment(dateStart.date, "YYYY-MM-DD").format("DD-MM-YYYY") : "No Disponible"}
                                        </td>
                                        <td className="align-middle fs-5">
                                            {horaInicio && horaFin ? `${dict.startTime} - ${dict.endTime}` : "No Disponible"}
                                        </td>
                                        <td className="align-middle me-5">
                                            <button className="btn btn-primary btn-block p-1" onClick={() => handleOpenConfirmModal(tid)}>
                                                {textMeetings.modal_aceptar}
                                            </button>
                                        </td>
                                    </tr>
                                );
                            });
                        })()}
                    </tbody>
                </table>
            </div>
        )}
    </DialogContent>
    <DialogActions>
        <Button onClick={handleCloseModal} color="primary">
            {textMeetings.modal_3_cerrar}
        </Button>
    </DialogActions>
</Dialog>

            {acceptConfirmationDialog}
            {rejectConfirmationDialog}
            <Dialog open={openConfirmModal} onClose={() => setOpenConfirmModal(false)}>
    <DialogTitle>{textMeetings.confirmacion}</DialogTitle>
    <DialogContent>
        <p>{textMeetings.modal_confirmacion_donación_directa}</p>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleConfirmMeeting} color="primary">
            {textMeetings.positivo}
        </Button>
        <Button onClick={() => setOpenConfirmModal(false)} color="primary">
            {textMeetings.negativo}
        </Button>
    </DialogActions>
</Dialog>
<Dialog open={openRejectDirectDonation} onClose={() => setOpenRejectDirectDonation(false)}>
    <DialogTitle>{textMeetings.confirmacion}</DialogTitle>
    <DialogContent>
        <p>{textMeetings.modal_confirmacion_rechazo_donación_directa}</p>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleConfirmRejectDirectDonation} color="primary">
            {textMeetings.positivo}
        </Button>
        <Button onClick={() => setOpenRejectDirectDonation(false)} color="primary">
            {textMeetings.negativo}
        </Button>
    </DialogActions>
</Dialog>

        </div>

                                        
    )
}

export default MeetingsSolicitudesRecibidas;

