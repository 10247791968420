import React, { useState, useEffect } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import moment from 'moment';
import Tabla from '../../components/Tabla/Tabla';
import { useGetUpcomingActivitiesByUserQuery, useSendActivityFeedbackMutation, useCancelUpcomingActivityByUserMutation } from '../../redux/features/events/eventsApiSlice';
import { useSelector, useDispatch } from 'react-redux';
import { selectPersonalData } from '../../redux/features/user/userSlice';
import { Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import FeedbackDialog from './FeedbackDialog';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';

interface UpcomingActivitiesUserProps {
    onRefetch?: (refetch: () => void) => void;
}

interface Activity {
    id: number;
    eventName: string;
    activityName: string;
    activityType: string;
    startDate: string;
    endDate: string;
    statusName: string;
    statusId: number;
    menteeName: string;
    mentorName: string;
    availableTimeId: number;
    hasMentorFeedback: boolean;
    hasMenteeFeedback: boolean;
    roleInActivity?: string;  
}

interface FeedbackFormValues {
    activityRating: number;
    activityFeedback: string;
    counterpartRating: number;
    maintainContact: boolean;
    isMentor: boolean;
}

const UpcomingActivitiesUser: React.FC<UpcomingActivitiesUserProps> = ({ onRefetch }) => {
    const dispatch = useDispatch();
    const user_personal_data = useSelector(selectPersonalData);
    const user_id = user_personal_data.Id;
    const [reload, setReload] = useState(false);
    const [selectedActivityId, setSelectedActivityId] = useState<number | null>(null);
    const [selectedAvailableTimeId, setSelectedAvailableTimeId] = useState<number | null>(null);
    const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [counterpartName, setCounterpartName] = useState("");
    const [isMentor, setIsMentor] = useState(false);
    const [currentMenteeName, setCurrentMenteeName] = useState("");
    const [pageSize, setPageSize] = useState<number>(10);  // Nuevo estado para el tamaño de página
    const [page, setPage] = useState<number>(0);  // Nuevo estado para la página actual

    const { data: activities = [], isLoading: isLoadingActivities, refetch, error: activitiesError } = useGetUpcomingActivitiesByUserQuery(user_id);
    const [sendActivityFeedback] = useSendActivityFeedbackMutation();
    const [cancelUpcomingActivity] = useCancelUpcomingActivityByUserMutation();

    useEffect(() => {
        if (reload) {
            refetch();
            setReload(false);
        }
    }, [reload, refetch]);

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (onRefetch) {
            onRefetch(refetch);
        }
    }, [onRefetch, refetch]);

    const handleEvaluation = (id: number, availableTimeId: number, mentorName: string, menteeName: string, roleInActivity: string) => {
        setSelectedActivityId(id);
        setSelectedAvailableTimeId(availableTimeId);
        const isMentor = roleInActivity === 'Mentor';
        setCounterpartName(isMentor ? menteeName : mentorName);
        setIsMentor(isMentor);
        setCurrentMenteeName(menteeName);
        setOpenFeedbackDialog(true);
    };

    const handleFeedbackSubmit = async (values: FeedbackFormValues) => {
        if (selectedAvailableTimeId !== null) {
            const feedbackData = {
                ...values,
                availableTimeId: selectedAvailableTimeId,
                isMentor 
            };

            let new_alert = { id: `sending_feedback_${Date.now()}`, type: "loading", title: "Enviando feedback", desc: "", close: false, timeout: 0 };
            dispatch(addAlert({ alert: new_alert }));

            try {
                await sendActivityFeedback(feedbackData).unwrap();
                dispatch(deleteAlert({ alert_id: new_alert.id }));
                let new_alert2 = { id: `feedback_sent_${Date.now()}`, type: "success", title: "Feedback enviado", desc: "El feedback ha sido enviado exitosamente", close: true, timeout: 0 };
                dispatch(addAlert({ alert: new_alert2 }));
                setReload(true);
            } catch (error) {
                dispatch(deleteAlert({ alert_id: new_alert.id }));
                let new_alert2 = { id: `feedback_error_${Date.now()}`, type: "error", title: "Error al enviar feedback", desc: "Hubo un error al enviar el feedback", close: true, timeout: 0 };
                dispatch(addAlert({ alert: new_alert2 }));
            }
        }
        setOpenFeedbackDialog(false);
    };

    const handleCancel = (id: number) => {
        setSelectedActivityId(id);
        setOpenConfirm(true);   
    };

    const confirmCancel = async () => {
        if (selectedActivityId === null) return;

        let new_alert = { id: `canceling_activity_${Date.now()}`, type: "loading", title: "Cancelando actividad", desc: "", close: false, timeout: 0 };
        dispatch(addAlert({ alert: new_alert }));

        try {
            await cancelUpcomingActivity({ activityId: selectedActivityId, userId: user_id }).unwrap();
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let new_alert2 = { id: `activity_canceled_${Date.now()}`, type: "success", title: "Actividad cancelada", desc: "La actividad ha sido cancelada exitosamente", close: true, timeout: 0 };
            dispatch(addAlert({ alert: new_alert2 }));
            setReload(true);
        } catch (error) {
            dispatch(deleteAlert({ alert_id: new_alert.id }));
            let new_alert2 = { id: `cancel_activity_error_${Date.now()}`, type: "error", title: "Error al cancelar", desc: "Hubo un error al cancelar la actividad", close: true, timeout: 0 };
            dispatch(addAlert({ alert: new_alert2 }));
        } finally {
            setOpenConfirm(false);
            setSelectedActivityId(null);
        }
    };

    const isFetchBaseQueryError = (error: any): error is FetchBaseQueryError => {
        return error && 'status' in error;
    };

    const renderCellWithTooltip = (params: GridRenderCellParams<any>) => (
        <Tooltip title={params.value}>
            <span>{params.value}</span>
        </Tooltip>
    );

    const columnas: GridColDef[] = [
        { 
            headerName: "Evento", 
            field: "eventName", 
            flex: 1,
            minWidth: 150,
            renderCell: renderCellWithTooltip 
        },
        { 
            headerName: "Actividad", 
            field: "activityType", 
            flex: 1,
            minWidth: 150,
            renderCell: renderCellWithTooltip 
        },
        { 
            headerName: "Temática", 
            field: "activityName", 
            flex: 1,
            minWidth: 200,
            renderCell: renderCellWithTooltip 
        },
        {
            headerName: "Inicio", 
            field: "startDate", 
            flex: 1,
            minWidth: 130,
            renderCell: (cellvalues) => {
                if (!cellvalues.value) return null;
                const datetime = moment(cellvalues.value);
                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div>{datetime.format("DD-MM-YYYY")}</div>
                        <div>{datetime.format("HH:mm")}</div>
                    </div>
                );
            }
        },
        {
            headerName: "Fin", 
            field: "endDate", 
            flex: 1,
            minWidth: 130,
            renderCell: (cellvalues) => {
                if (!cellvalues.value) return null;
                const datetime = moment(cellvalues.value);
                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div>{datetime.format("DD-MM-YYYY")}</div>
                        <div>{datetime.format("HH:mm")}</div>
                    </div>
                );
            }
        },
        { 
            headerName: "Contraparte",  
            field: "roleInActivity", 
            flex: 1,
            minWidth: 170,
            renderCell: (cellvalues) => (
                <span>{cellvalues.row.roleInActivity === 'Mentor' ? cellvalues.row.menteeName : cellvalues.row.mentorName}</span>
            )
        },
        { 
            headerName: "Estado", 
            field: "statusName", 
            flex: 1,
            minWidth: 170,
            renderCell: renderCellWithTooltip 
        },
        {
            headerName: "Opciones", 
            field: "options", 
            flex: 1,
            minWidth: 150,
            maxWidth: 200,
            renderCell: (cellvalues) => {
                const { statusId, hasMenteeFeedback, hasMentorFeedback, roleInActivity } = cellvalues.row;
            
                const isDisabledEvaluation = statusId === 4 && (
                    (roleInActivity === 'Mentor' && hasMentorFeedback) || 
                    (roleInActivity === 'Mentee' && hasMenteeFeedback)
                );
            
                return (
                    <div className="d-flex flex-column justify-content-center align-items-stretch w-100">
                        {statusId === 3 && (
                            <button
                                className="btn btn-danger btn-block p-1"
                                style={{
                                    backgroundColor: '#dc3545',
                                    color: '#fff',
                                    textTransform: 'none',
                                    padding: '5px 10px',
                                    fontSize: '0.875rem',
                                    minHeight: '30px',
                                    border: 'none',
                                    borderRadius: '4px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => handleCancel(cellvalues.row.id)}
                            >
                                Cancelar
                            </button>
                        )}
                        {statusId === 4 && (
                            <button
                                className="btn btn-primary btn-block p-1"
                                style={{
                                    backgroundColor: isDisabledEvaluation ? '#ccc' : '#007bff',
                                    color: '#fff',
                                    marginBottom: '5px',
                                    textTransform: 'none',
                                    padding: '5px 10px',
                                    fontSize: '0.875rem',
                                    minHeight: '30px',
                                    border: 'none',
                                    borderRadius: '4px',
                                    cursor: isDisabledEvaluation ? 'not-allowed' : 'pointer'
                                }}
                                onClick={() => !isDisabledEvaluation && handleEvaluation(cellvalues.row.id, cellvalues.row.availableTimeId, cellvalues.row.mentorName, cellvalues.row.menteeName, cellvalues.row.roleInActivity)}
                                disabled={isDisabledEvaluation}
                            >
                                Evaluar
                            </button>
                        )}
                    </div>
                );
            }
        }
    ];

    const tableData = activities?.map((activity: Activity) => ({
        id: activity.id,
        eventName: activity.eventName,
        activityName: activity.activityName,
        activityType: activity.activityType,  
        startDate: activity.startDate,
        endDate: activity.endDate,  
        statusName: activity.statusName,
        statusId: activity.statusId,
        menteeName: activity.menteeName,
        mentorName: activity.mentorName,
        availableTimeId: activity.availableTimeId,
        hasMentorFeedback: activity.hasMentorFeedback,
        hasMenteeFeedback: activity.hasMenteeFeedback,
        roleInActivity: activity.roleInActivity  
    })) || [];

    return isLoadingActivities ? (
        <div className='table-container-loading'>
            <p>Cargando actividades...</p>
        </div>
    ) : activitiesError && isFetchBaseQueryError(activitiesError) && activitiesError.status === 404 ? (
        <div className='table-container'>
            <p>No hay actividades próximas disponibles.</p>
        </div>
    ) : (
        <div className='table-container' style={{ width: '100%', overflowX: 'auto', paddingBottom: '80px', minHeight: '500px' }}>
            <Tabla 
                columnas={columnas} 
                datos={tableData} 
                autoHeight 
                pageSize={pageSize}  // Pasar pageSize
                setPageSize={setPageSize}  // Pasar setPageSize
                page={page}  // Pasar page
                onPageChange={setPage}  // Pasar onPageChange
            />
            <FeedbackDialog 
                open={openFeedbackDialog} 
                onClose={() => setOpenFeedbackDialog(false)} 
                onSubmit={handleFeedbackSubmit} 
                counterpartName={counterpartName} 
                isMentor={counterpartName === currentMenteeName}  // isMentor es true si el usuario es un mentor
            />
            <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)} maxWidth="xs" fullWidth>
                <DialogTitle>Confirmación</DialogTitle>
                <DialogContent>
                    <p>¿Estás seguro que deseas cancelar la actividad?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={confirmCancel} color="primary">Sí</Button>
                    <Button onClick={() => setOpenConfirm(false)} color="primary">No</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UpcomingActivitiesUser;
