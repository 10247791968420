    import { useEffect, useState } from 'react';
    import { useDispatch } from 'react-redux';
    import { Dialog, DialogTitle, DialogContent, DialogActions, Button, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
    import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';
    import AutorenewIcon from '@mui/icons-material/Autorenew';
    import CloseIcon from '@mui/icons-material/Close';
    import { useGetEventsQuery, useGetEventByIdQuery, useAddEventMutation, useUpdateEventMutation, useDeleteEventMutation, useAddActivityMutation, useGetActivitiesQuery, useUpdateActivityMutation, useDeleteActivityMutation, useGetAllAvailableTimesQuery } from '../../redux/features/events/eventsApiSlice';
    import EventForm from './EventForm';
    import EventTable from './EventTable';
    import ActivityTable from './ActivityTable';
    import ActivityForm from './ActivityForm';
    import ActivityFormCreate from './ActivityFormCreate';  
    import BlockScheduleTable from './BlockScheduleTable';
    import BlockScheduleForm from './BlockScheduleForm';

    type Event = {
        id: number;
        event: string;
        date: string;
        type: string;
        description?: string;
        status: string;
        qr: string;
        visible: number;
    };

    type Activity = {
        id: number;
        activityName: string;
        startTime: string;
        duration: number;
        blockDuration: number;
        breakTime: number;
        additionalTime: number;
        type: string;
        description: string;
        status: string;
        qr: string;
        visible: number;
        eventId: number;
        industryId: number;
    };

    type BlockSchedule = {
        id: number;
        eventName: string;
        activityName: string;
        activityType: string;
        startTime: string;
        endTime: string;
        mentor: string;
        mentorEmail: string;
        mentorId: number | null;
        mentee: string;
        menteeEmail: string;
        menteeId: number | null;
    };

    const useEventData = (id: number | null) => {
        const { data, error, refetch, isFetching } = useGetEventByIdQuery(id!, { skip: !id });

        useEffect(() => {
            if (id) {
                refetch();
            }
        }, [id, refetch]);

        return { eventData: data, error, refetch };
    };

    const useActivityData = (id: number | null) => {
        const { data, error, refetch, isFetching } = useGetActivitiesQuery(id!, { skip: !id });

        useEffect(() => {
            if (id) {
                refetch();
            }
        }, [id, refetch]);

        return { activityData: data, error, refetch };
    };

    const Events = () => {
        const dispatch = useDispatch();
        const { data: eventsData, error, isLoading, refetch: refetchEvents } = useGetEventsQuery({});
        const { refetch: refetchActivities } = useGetActivitiesQuery({});
        const { data: availableTimes, refetch: refetchAvailableTimes } = useGetAllAvailableTimesQuery({});
        const [addEvent] = useAddEventMutation();
        const [updateEvent] = useUpdateEventMutation();
        const [deleteEvent] = useDeleteEventMutation();
        const [addActivity] = useAddActivityMutation();
        const [updateActivity] = useUpdateActivityMutation();
        const [deleteActivity] = useDeleteActivityMutation();

        const [openCancelConfirmation, setOpenCancelConfirmation] = useState(false);
        const [selectedEventId, setSelectedEventId] = useState<number | null>(null);
        const [selectedActivityId, setSelectedActivityId] = useState<number | null>(null);
        const [selectedBlockSchedule, setSelectedBlockSchedule] = useState<BlockSchedule | null>(null);
        const [openDialog, setOpenDialog] = useState(false);
        const [isSubmitting, setIsSubmitting] = useState(false);
        const [isEdit, setIsEdit] = useState(false);
        const [isDeleting, setIsDeleting] = useState(false);
        const [loading, setLoading] = useState(false);
        const [formType, setFormType] = useState<'event' | 'activity' | 'blockSchedule'>('event');
        const [isAdding, setIsAdding] = useState(false);  
        const [filter, setFilter] = useState<string>('');

        const { eventData: initialValues, refetch: refetchEventData } = useEventData(selectedEventId);
        const { activityData: initialActivityValues, refetch: refetchActivityData } = useActivityData(selectedActivityId);

        const handleOpenCancelConfirmation = (id: number, type: 'event' | 'activity') => {
            if (type === 'event') {
                setSelectedEventId(id);
            } else {
                setSelectedActivityId(id);
            }
            setOpenCancelConfirmation(true);
        };

        const handleCancelConfirmation = async () => {
            if (selectedEventId !== null && formType === 'event') {
                setIsDeleting(true);
                let new_alert = { id: `canceling_event_${Date.now()}`, type: "loading", title: "Cancelando evento...", desc: "", close: false, timeout: 2000 };
                dispatch(addAlert({ alert: new_alert }));
                try {
                    await deleteEvent(selectedEventId).unwrap();
                    dispatch(deleteAlert({ alert_id: new_alert.id }));
                    let new_alert2 = { id: `event_canceled_${Date.now()}`, type: "success", title: "Evento cancelado", desc: "", close: true, timeout: 2000 };
                    dispatch(addAlert({ alert: new_alert2 }));
                    refetchEvents();
                    refetchActivities();
                } catch (err) {
                    dispatch(deleteAlert({ alert_id: new_alert.id }));
                    let new_alert2 = { id: `cancel_event_error_${Date.now()}`, type: "error", title: "Error al cancelar evento", desc: "", close: true, timeout: 2000 };
                    dispatch(addAlert({ alert: new_alert2 }));
                }
                setIsDeleting(false);
            } else if (selectedActivityId !== null && formType === 'activity') {
                setIsDeleting(true);
                let new_alert = { id: `canceling_activity_${Date.now()}`, type: "loading", title: "Cancelando actividad...", desc: "", close: false, timeout: 2000 };
                dispatch(addAlert({ alert: new_alert }));
                try {
                    await deleteActivity(selectedActivityId).unwrap();
                    dispatch(deleteAlert({ alert_id: new_alert.id }));
                    let new_alert2 = { id: `activity_canceled_${Date.now()}`, type: "success", title: "Actividad cancelada", desc: "", close: true, timeout: 2000 };
                    dispatch(addAlert({ alert: new_alert2 }));
                    refetchActivities();
                } catch (err) {
                    dispatch(deleteAlert({ alert_id: new_alert.id }));
                    let new_alert2 = { id: `cancel_activity_error_${Date.now()}`, type: "error", title: "Error al cancelar actividad", desc: "", close: true, timeout: 2000 };
                    dispatch(addAlert({ alert: new_alert2 }));
                }
                setIsDeleting(false);
            }
            setOpenCancelConfirmation(false);
        };

        const handleAddEvent = async (values: any) => {
            setIsSubmitting(true);

            let new_alert = { id: `adding_event_${Date.now()}`, type: "loading", title: "Cargando", desc: "", close: false, timeout: 2000 };

            dispatch(addAlert({ alert: new_alert }));

            try {
                await addEvent(values).unwrap();
                refetchEvents();
                refetchActivities();

                dispatch(deleteAlert({ alert_id: new_alert.id }));
                let new_alert2 = { id: `event_added_${Date.now()}`, type: "success", title: "Evento añadido", desc: "", close: true, timeout: 2000 };
                dispatch(addAlert({ alert: new_alert2 }));
            } catch (err) {
                dispatch(deleteAlert({ alert_id: new_alert.id }));
                let new_alert2 = { id: `add_event_error_${Date.now()}`, type: "error", title: "Error al añadir evento", desc: "", close: true, timeout: 2000 };
                dispatch(addAlert({ alert: new_alert2 }));
            }

            setIsSubmitting(false);
            handleCloseDialog();
        };

        const handleAddActivity = async (values: any) => {
            setIsSubmitting(true);

            let new_alert = { id: `adding_activity_${Date.now()}`, type: "loading", title: "Cargando", desc: "", close: false, timeout: 2000 };

            dispatch(addAlert({ alert: new_alert }));

            try {
                //console.log('Datos de la actividad:', values);
                await addActivity(values).unwrap();
                refetchActivities();

                dispatch(deleteAlert({ alert_id: new_alert.id }));
                let new_alert2 = { id: `activity_added_${Date.now()}`, type: "success", title: "Actividad añadida", desc: "", close: true, timeout: 2000 };
                dispatch(addAlert({ alert: new_alert2 }));
            } catch (err) {
                dispatch(deleteAlert({ alert_id: new_alert.id }));
                let new_alert2 = { id: `add_activity_error_${Date.now()}`, type: "error", title: "Error al añadir actividad", desc: "", close: true, timeout: 2000 };
                dispatch(addAlert({ alert: new_alert2 }));
            }

            setIsSubmitting(false);
            handleCloseDialog();
        };

        const handleEdit = async (id: number | BlockSchedule, type: 'event' | 'activity' | 'blockSchedule') => {
            if (type === 'event') {
                setSelectedEventId(id as number);
            } else if (type === 'activity') {
                setSelectedActivityId(id as number);
            } else if (type === 'blockSchedule') {
                const blockSchedule = id as BlockSchedule;
                setSelectedBlockSchedule(blockSchedule);
            }
            setIsEdit(true);
            setIsAdding(false);  
            setFormType(type);
            setOpenDialog(true);
        };

        const handleUpdate = async (values: any) => {
            setIsSubmitting(true);

            let new_alert = { id: `updating_${formType}_${Date.now()}`, type: "loading", title: `Actualizando ${formType === 'event' ? 'evento' : formType === 'activity' ? 'actividad' : 'bloque horario'}`, desc: "", close: false, timeout: 2000 };

            dispatch(addAlert({ alert: new_alert }));

            try {
                if (formType === 'event') {
                    await updateEvent({ id: selectedEventId!, updatedEvent: values }).unwrap();
                    refetchEvents();
                } else if (formType === 'activity') {
                    await updateActivity({ id: selectedActivityId!, updatedActivity: values }).unwrap();
                    refetchActivities();
                } else if (formType === 'blockSchedule') {
                    
                }

                dispatch(deleteAlert({ alert_id: new_alert.id }));
                let new_alert2 = { id: `${formType}_updated_${Date.now()}`, type: "success", title: `${formType === 'event' ? 'Evento' : formType === 'activity' ? 'Actividad' : 'Bloque de Horario'} actualizado(a)`, desc: "", close: true, timeout: 2000 };
                dispatch(addAlert({ alert: new_alert2 }));
            } catch (err) {
                dispatch(deleteAlert({ alert_id: new_alert.id }));
                let new_alert2 = { id: `update_${formType}_error_${Date.now()}`, type: "error", title: `Error al actualizar ${formType === 'event' ? 'evento' : formType === 'activity' ? 'actividad' : 'bloque horario'}`, desc: "", close: true, timeout: 2000 };
                dispatch(addAlert({ alert: new_alert2 }));
            }

            setIsSubmitting(false);
            handleCloseDialog();
        };

        const handleDelete = (id: number, type: 'event' | 'activity') => {
            setFormType(type);
            handleOpenCancelConfirmation(id, type);
        };

        const handleCloseDialog = () => {
            setOpenDialog(false);
            setIsEdit(false);
            setIsAdding(false);
            setSelectedEventId(null);
            setSelectedActivityId(null);
            setSelectedBlockSchedule(null);
        };

        const handleOpenDialog = (type: 'event' | 'activity', isAdd: boolean = false) => {  
            setFormType(type);
            setIsAdding(isAdd);  
            setOpenDialog(true);
        };

        useEffect(() => {
            if (!isLoading && loading) {
                setLoading(false);
            }
        }, [isLoading, loading]);

        const handleRefetch = () => {
            setLoading(true);
            refetchEvents();
            refetchActivities();
            refetchAvailableTimes();  
        };

        return (
            <div className='row'>
                <div className='col-12 mb-4'>
                    <div className='d-flex justify-content-start align-items-center mb-2'>
                        <h3 className='mb-0 mr-2 font-italic'><b>Eventos</b></h3>
                        <div className="btn-refresh mt-1" onClick={handleRefetch}>
                            <AutorenewIcon fontSize='small' />
                        </div>
                    </div>
                    <p>
                        Lista de eventos disponibles.
                    </p>
                </div>
                <div className='col-12 mb-4'>
                    <EventTable
                        events={eventsData}
                        loading={loading || isLoading}
                        handleEdit={(id) => handleEdit(id, 'event')}
                        handleDelete={(id) => handleDelete(id, 'event')}
                        handleOpenDialog={() => handleOpenDialog('event')}
                    />
                </div>
                
                <div className='col-12 mb-4'>
                    <div className='d-flex justify-content-start align-items-center mb-2'>
                        <h3 className='mb-0 mr-2 font-italic'><b>Actividades</b></h3>
                        <div className="btn-refresh mt-1" onClick={handleRefetch}>
                            <AutorenewIcon fontSize='small' />
                        </div>
                    </div>
                    <p>
                        Lista de actividades disponibles.
                    </p>
                </div>
                <div className='col-12 mb-4'>
                    <ActivityTable
                        handleEdit={(id) => handleEdit(id, 'activity')}
                        handleDelete={(id) => handleDelete(id, 'activity')}
                        handleOpenDialog={() => handleOpenDialog('activity', true)}  
                    />
                </div>

                <div className='col-12 mb-4'>
                    <div className='d-flex justify-content-start align-items-center mb-2'>
                        <h3 className='mb-0 mr-2 font-italic'><b>Bloques de Horarios</b></h3>
                        <div className="btn-refresh mt-1" onClick={handleRefetch}>
                            <AutorenewIcon fontSize='small' />
                        </div>
                    </div>
                    <p>
                        Lista de bloques horarios disponibles.
                    </p>
                </div>
                <div className='col-12 mb-4'>
                    {// <BlockScheduleTable
                    //   handleEdit={(blockSchedule) => handleEdit(blockSchedule, 'blockSchedule')}
                    //   onFilterChange={setFilter}  
                    //   filter={filter}   
                    // /> 
                    
                    //<BlockScheduleTable
        //handleEdit={(blockSchedule) => handleEdit(blockSchedule, 'blockSchedule')}
    ///>
    }

 <BlockScheduleTable
                       handleEdit={(blockSchedule) => handleEdit(blockSchedule, 'blockSchedule')}
                       onFilterChange={setFilter}  
                       filter={filter}   
                     /> 
                    
                </div>

                <Dialog open={openCancelConfirmation} onClose={() => setOpenCancelConfirmation(false)}>
                    <DialogTitle>Confirmación</DialogTitle>
                    <DialogContent>
                        <p>¿Estás seguro de que deseas cancelar este {formType === 'event' ? 'evento' : 'actividad'}?</p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelConfirmation} color="primary" disabled={isDeleting}>
                            Confirmar
                        </Button>
                        <Button onClick={() => setOpenCancelConfirmation(false)} color="primary" disabled={isDeleting}>
                            Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="xs" fullWidth>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                {formType === 'event' ? 'Formulario de Evento' : formType === 'activity' ? 'Formulario de Actividad' : 'Formulario de Bloque de Horario'}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        {formType === 'event' ? (
                            <EventForm
                                handleSubmit={isEdit ? handleUpdate : handleAddEvent}
                                handleClose={handleCloseDialog}
                                isSubmitting={isSubmitting}
                                initialValues={isEdit ? initialValues : undefined}
                            />
                        ) : formType === 'activity' ? (
                            isAdding ? (  
                                <ActivityFormCreate
                                    handleSubmit={handleAddActivity}
                                    handleClose={handleCloseDialog}
                                    isSubmitting={isSubmitting}
                                    initialValues={{}}  
                                />
                            ) : (
                                <ActivityForm
                                    handleSubmit={handleUpdate}
                                    handleClose={handleCloseDialog}
                                    isSubmitting={isSubmitting}
                                    initialValues={initialActivityValues}
                                />
                            )
                        ) : (
                            <BlockScheduleForm
                                handleSubmit={handleUpdate}
                                handleClose={handleCloseDialog}
                                isSubmitting={isSubmitting}
                                initialValues={isEdit && selectedBlockSchedule ? {
                                    id: selectedBlockSchedule.id,
                                    mentorEmail: selectedBlockSchedule.mentorEmail || '',
                                    menteeEmail: selectedBlockSchedule.menteeEmail || ''
                                } : { id: null, mentorEmail: '', menteeEmail: '' }}
                                onSuccess={() => {
                                    refetchAvailableTimes();
                                    setFilter(filter);  
                                }}  
                            />
                        )}
                    </DialogContent>
                </Dialog>
            </div>
        );
    };

    export default Events;
