import React, { useState, useEffect, useRef } from 'react';
import { Box, Tab, Tabs, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import "../Meetings/Meetings.scss";
import { useGetAllActivitiesQuery, useCheckUserInTicketSalesEtMtuesdayQuery, useConfirmAssistMutation } from '../../redux/features/events/eventsApiSlice';
import ActivityTableUser from './ActivityTableUser';
import UpcomingActivitiesUser from './UpcomingActivitiesUser';
import ActivityHistory from './ActivityHistory';  
import { selectPersonalData } from '../../redux/features/user/userSlice';
import { useSelector } from "react-redux";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`events-tabpanel-${index}`}
      aria-labelledby={`events-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
};

function a11yProps(index: number): { id: string, 'aria-controls': string } {
  return {
    id: `events-tab-${index}`,
    'aria-controls': `events-tabpanel-${index}`,
  };
}

const EventsUsers: React.FC = () => {
  const user_personal_data = useSelector(selectPersonalData);
  const user_id = user_personal_data.Id;
  const navigate = useNavigate();
  const location = useLocation();
  const { idactividad } = useParams<{ idactividad?: string }>();
  const [value, setValue] = useState(0);
  const [openTicketDialog, setOpenTicketDialog] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({ open: false, message: '' });

  const menteeRefetchRef = useRef<() => void>(() => { });

  const { refetch: refetchActivities } = useGetAllActivitiesQuery({});
  const { data: ticketData, isLoading: isLoadingTicket, refetch: refetchTicket } = useCheckUserInTicketSalesEtMtuesdayQuery(user_id);
  const [confirmAssist] = useConfirmAssistMutation();

  useEffect(() => {
    refetchTicket();
  }, [location, refetchTicket]);

  useEffect(() => {
    if (!isLoadingTicket && !ticketData) {
      setOpenTicketDialog(true);
    }
  }, [ticketData, isLoadingTicket]);

  useEffect(() => {
    const mode = new URLSearchParams(location.search).get('mode');
    if (idactividad && mode === 'QR') {
      confirmAssist({ userId: user_id, qrCode: idactividad })
        .then((response: any) => {
          if ('error' in response) {
            setConfirmDialog({ open: true, message: "No tienes actividades asociadas a este QR" });
          } else {
            setConfirmDialog({ open: true, message: "Actividad confirmada" });
          }
        })
        .catch((error) => {
          console.error("Error confirmando la actividad:", error);
          setConfirmDialog({ open: true, message: "Error al confirmar la actividad" });
        });
    }
  }, [idactividad, location.search, user_id, confirmAssist]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (value !== newValue) {
      setValue(newValue);
    }
  };

  useEffect(() => {
    if (value === 1) {
      menteeRefetchRef.current();
    } else {
      refetchActivities();
    }
  }, [value, refetchActivities]);

  const handleClick = (buttonId: string, tabIndex: number) => {
    setValue(tabIndex);
  };

  if (isLoadingTicket) {
    return <div>Cargando...</div>;
  }

  const handleCloseTicketDialog = () => {
    setOpenTicketDialog(false);
    navigate('/');
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialog({ open: false, message: '' });
  };

  return (
    <div className='meetings-container padding-layout-x'>
      {/* Versión móvil */}
      <div className='d-block d-lg-none'>
        <div className='row no-gutters'>
          <div className='col-12'>
            <div
              className={`meetings-menu-item ${value === 0 ? 'active' : ''}`}
              onClick={() => handleClick('inscripcion-actividades', 0)}
            >
              Inscripción actividades
            </div>
          </div>
          <div className='col-12'>
            <div
              className={`meetings-menu-item ${value === 1 ? 'active' : ''}`}
              onClick={() => handleClick('proximas-actividades', 1)}
            >
              Próximas actividades
            </div>
          </div>
          <div className='col-12'>
            <div
              className={`meetings-menu-item ${value === 2 ? 'active' : ''}`}
              onClick={() => handleClick('activity-history', 2)}
            >
              Historial de actividades
            </div>
          </div>
        </div>
      </div>
      
      {/* Versión escritorio */}
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }} className="d-none d-lg-block">
          <Tabs value={value} onChange={handleChange} centered>
            <Tab className="meetings-menu-itemtab" label="Inscripción actividades" {...a11yProps(0)} />
            <Tab className="meetings-menu-itemtab" label="Próximas actividades" {...a11yProps(1)} />
            <Tab className="meetings-menu-itemtab" label="Historial de actividades" {...a11yProps(2)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <div className='col-12 mb-4'>
            <div className='d-flex justify-content-start align-items-center mb-2'>
              <h3 className='mb-0 mr-2 font-italic'><b>Inscripción actividades</b></h3>
              <div className="btn-refresh mt-1" onClick={() => refetchActivities()}>
                <AutorenewIcon fontSize='small' />
              </div>
            </div>
            <p>Lista de actividades disponibles para inscripción.</p>
            <ActivityTableUser handleViewSchedules={() => {}} />
          </div>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <div className='col-12 mb-4'>
            <div className='d-flex justify-content-start align-items-center mb-2'>
              <h3 className='mb-0 mr-2 font-italic'><b>Próximas actividades</b></h3>
              <div className="btn-refresh mt-1" onClick={() => menteeRefetchRef.current()}>
                <AutorenewIcon fontSize='small' />
              </div>
            </div>
            <p>Lista de próximas actividades programadas.</p>
            <UpcomingActivitiesUser onRefetch={refetch => { menteeRefetchRef.current = refetch; }} />
          </div>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <div className='col-12 mb-4'>
            <div className='d-flex justify-content-start align-items-center mb-2'>
              <h3 className='mb-0 mr-2 font-italic'><b>Historial de actividades</b></h3>
              <div className="btn-refresh mt-1" onClick={() => refetchActivities()}>
                <AutorenewIcon fontSize='small' />
              </div>
            </div>
            <p>Actividades realizadas.</p>
            <ActivityHistory />
          </div>
        </TabPanel>
      </Box>

      <Dialog open={openTicketDialog} onClose={handleCloseTicketDialog}>
        <DialogTitle>Ingreso eventos</DialogTitle>
        <DialogContent>
          <p>No estás registrado en ningún evento de Emprende Tu Mente. Ingresa a{' '}
            <a href="https://www.emprendetumente.org" target="_blank" rel="noopener noreferrer">
              www.emprendetumente.org
            </a>{' '}
            e infórmate sobre los eventos disponibles.
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTicketDialog} color="primary">Ok</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmDialog.open} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirmación de Actividad</DialogTitle>
        <DialogContent>
          <p>{confirmDialog.message}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">Cerrar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EventsUsers;
