import React, { useEffect, useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import moment from 'moment';
import Tabla from '../../components/Tabla/Tabla';
import { Tooltip } from '@mui/material';
import { useGetActivityHistoryByUserQuery, useSendActivityFeedbackMutation } from '../../redux/features/events/eventsApiSlice';
import { useSelector, useDispatch } from "react-redux";
import { selectPersonalData } from '../../redux/features/user/userSlice';
import FeedbackDialog from './FeedbackDialog';
import { addAlert, deleteAlert } from '../../redux/features/alerts/alertsSlice';

interface ActivityHistoryProps {}

interface Activity {
    id: number;
    eventName: string;
    activityName: string;
    activityType: string;
    startDate: string;
    endDate: string;
    statusName: string;
    statusId: number;
    mentorName: string;
    menteeName: string;
    feedbackGiven: boolean;  
    hasMenteeFeedback: boolean;  
    hasMentorFeedback: boolean;
    roleInActivity: string;
    availableTimeId: number;
    counterpartName: string;   
}

const ActivityHistory: React.FC<ActivityHistoryProps> = () => {
    const dispatch = useDispatch();
    const user_personal_data = useSelector(selectPersonalData);
    const user_id = user_personal_data.Id;
    const { data: activities = [], refetch } = useGetActivityHistoryByUserQuery(user_id);
    const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
    const [selectedAvailableTimeId, setSelectedAvailableTimeId] = useState<number | null>(null);
    const [counterpartName, setCounterpartName] = useState("");
    const [isMentor, setIsMentor] = useState(false); 
    const [sendActivityFeedback] = useSendActivityFeedbackMutation();

    // Estados de paginación
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);

    useEffect(() => {
        refetch();
    }, [refetch]);

    const handleEvaluation = (availableTimeId: number, counterpartName: string, isMentorRole: boolean) => {
        setSelectedAvailableTimeId(availableTimeId);
        setCounterpartName(counterpartName); 
        setIsMentor(isMentorRole); 
        setOpenFeedbackDialog(true);
    };

    const handleFeedbackSubmit = async (values: any) => {
        if (selectedAvailableTimeId !== null) {
            const feedbackData = {
                ...values,
                availableTimeId: selectedAvailableTimeId,
                isMentor: isMentor    
            };

            let new_alert = { id: `sending_feedback_${Date.now()}`, type: "loading", title: "Enviando feedback", desc: "", close: false, timeout: 0 };
            dispatch(addAlert({ alert: new_alert }));

            try {
                await sendActivityFeedback(feedbackData).unwrap();
                dispatch(deleteAlert({ alert_id: new_alert.id }));
                let new_alert2 = { id: `feedback_sent_${Date.now()}`, type: "success", title: "Feedback enviado", desc: "El feedback ha sido enviado exitosamente", close: true, timeout: 0 };
                dispatch(addAlert({ alert: new_alert2 }));
                refetch();
            } catch (error) {
                dispatch(deleteAlert({ alert_id: new_alert.id }));
                let new_alert2 = { id: `feedback_error_${Date.now()}`, type: "error", title: "Error al enviar feedback", desc: "Hubo un error al enviar el feedback", close: true, timeout: 0 };
                dispatch(addAlert({ alert: new_alert2 }));
            }
        }
        setOpenFeedbackDialog(false);
    };

    const renderCellWithTooltip = (params: GridRenderCellParams<any>) => (
        <Tooltip title={params.value}>
            <span>{params.value}</span>
        </Tooltip>
    );

    const columnas: GridColDef[] = [
        { 
            headerName: "Evento", 
            field: "eventName", 
            flex: 1,
            minWidth: 150,
            renderCell: renderCellWithTooltip 
        },
        { 
            headerName: "Actividad", 
            field: "activityType", 
            flex: 1,
            minWidth: 150,
            renderCell: renderCellWithTooltip 
        },
        { 
            headerName: "Temática", 
            field: "activityName", 
            flex: 1,
            minWidth: 200,
            renderCell: renderCellWithTooltip 
        },
        {
            headerName: "Inicio", 
            field: "startDate", 
            flex: 1,
            minWidth: 130,
            renderCell: (cellvalues) => {
                if (!cellvalues.value) return null;
                const datetime = moment(cellvalues.value);
                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div>{datetime.format("DD-MM-YYYY")}</div>
                        <div>{datetime.format("HH:mm")}</div>
                    </div>
                );
            }
        },
        {
            headerName: "Fin", 
            field: "endDate", 
            flex: 1,
            minWidth: 130,
            renderCell: (cellvalues) => {
                if (!cellvalues.value) return null;
                const datetime = moment(cellvalues.value);
                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div>{datetime.format("DD-MM-YYYY")}</div>
                        <div>{datetime.format("HH:mm")}</div>
                    </div>
                );
            }
        },
        { 
            headerName: "Contraparte",  
            field: "counterpartName",  
            flex: 1,
            minWidth: 150,
            renderCell: renderCellWithTooltip 
        },
        { 
            headerName: "Estado", 
            field: "statusName", 
            flex: 1,
            minWidth: 170,
            renderCell: renderCellWithTooltip 
        },
        {
            headerName: "Evaluar", 
            field: "evaluate", 
            flex: 1,
            minWidth: 150,
            maxWidth: 200,
            renderCell: (cellvalues) => {
                const { feedbackGiven, statusId, counterpartName, roleInActivity, availableTimeId, hasMentorFeedback, hasMenteeFeedback } = cellvalues.row;

                if (statusId === 4) {
                    const isDisabledEvaluation = 
                        (roleInActivity === 'Mentor' && hasMentorFeedback) || 
                        (roleInActivity === 'Mentee' && hasMenteeFeedback);

                return (
                    <button
                        className="btn btn-primary btn-block p-1"
                        style={{
                            backgroundColor: isDisabledEvaluation ? '#ccc' : '#007bff',
                            color: '#fff',
                            textTransform: 'none',
                            padding: '5px 10px',
                            fontSize: '0.875rem',
                            minHeight: '30px',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: isDisabledEvaluation ? 'not-allowed' : 'pointer'
                        }}
                        onClick={() => !isDisabledEvaluation && handleEvaluation(availableTimeId, counterpartName, roleInActivity === 'Mentor')}
                        disabled={isDisabledEvaluation}
                    >
                        {isDisabledEvaluation ? 'Evaluado' : 'Evaluar'}
                    </button>
                );
            }
            }
        }
    ];

    const tableData = activities.map((activity: Activity) => ({
        id: activity.id,
        eventName: activity.eventName,
        activityName: activity.activityName,
        activityType: activity.activityType,
        startDate: activity.startDate,
        endDate: activity.endDate,
        statusName: activity.statusName,
        statusId: activity.statusId,
        mentorName: activity.mentorName,
        menteeName: activity.menteeName,
        counterpartName: activity.counterpartName,   
        feedbackGiven: activity.hasMenteeFeedback,  
        hasMentorFeedback: activity.hasMentorFeedback,
        hasMenteeFeedback: activity.hasMenteeFeedback,
        roleInActivity: activity.roleInActivity,
        availableTimeId: activity.id
    }));

    return (
        <div className='table-container' style={{ width: '100%', overflowX: 'auto' }}>
            <Tabla 
                columnas={columnas} 
                datos={tableData} 
                pageSize={pageSize} 
                setPageSize={setPageSize} 
                page={page} 
                onPageChange={setPage} 
            />
            <FeedbackDialog 
                open={openFeedbackDialog} 
                onClose={() => setOpenFeedbackDialog(false)} 
                onSubmit={handleFeedbackSubmit} 
                counterpartName={counterpartName} 
                isMentor={isMentor}  
            />
        </div>
    );
};

export default ActivityHistory;
