import React, { useState, useEffect } from 'react';
import { Box, Select, MenuItem, FormControl, SelectChangeEvent } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import Tabla from '../../components/Tabla/Tabla';
import { useGetAllAvailableTimesQuery } from '../../redux/features/events/eventsApiSlice';

type BlockSchedule = {
    id: number;
    eventName: string;
    activityName: string;
    activityType: string;
    startTime: string;
    endTime: string;
    mentor: string;
    mentorEmail: string;
    mentorId: number | null;
    mentee: string;
    menteeEmail: string;
    menteeId: number | null;
};

interface BlockScheduleTableProps {
    handleEdit: (blockSchedule: BlockSchedule) => void;
    onFilterChange: React.Dispatch<React.SetStateAction<string>>;
    filter: string;
}

const BlockScheduleTable: React.FC<BlockScheduleTableProps> = ({ handleEdit }) => {
    const { data: availableTimes, isLoading } = useGetAllAvailableTimesQuery({});
    const [tableData, setTableData] = useState<BlockSchedule[]>([]);
    const [filteredData, setFilteredData] = useState<BlockSchedule[]>([]);

    // Estados para los filtros
    const [selectedEvent, setSelectedEvent] = useState<string>('');
    const [selectedActivity, setSelectedActivity] = useState<string>('');
    const [selectedTheme, setSelectedTheme] = useState<string>('');
    const [selectedMentor, setSelectedMentor] = useState<string>('');
    const [selectedMentee, setSelectedMentee] = useState<string>('');
    const [selectedStartDate, setSelectedStartDate] = useState<string>('');  // Filtro de fecha de inicio
    const [selectedEndDate, setSelectedEndDate] = useState<string>('');      // Filtro de fecha de fin

    // Estados para la paginación
    const [page, setPage] = useState<number>(0);  // DataGrid usa 0-based index para páginas
    const [pageSize, setPageSize] = useState<number>(10);  // Tamaño de página

    useEffect(() => {
        if (availableTimes) {
            setTableData(availableTimes);
            filterData();
        }
    }, [availableTimes, selectedEvent, selectedActivity, selectedTheme, selectedMentor, selectedMentee, selectedStartDate, selectedEndDate, page, pageSize]);

    const filterData = () => {
        let filtered = availableTimes || [];

        // Aplicar todos los filtros seleccionados
        if (selectedEvent) {
            filtered = filtered.filter((data: BlockSchedule) => data.eventName === selectedEvent);
        }
        if (selectedActivity) {
            filtered = filtered.filter((data: BlockSchedule) => data.activityName === selectedActivity);
        }
        if (selectedTheme) {
            filtered = filtered.filter((data: BlockSchedule) => data.activityType === selectedTheme);
        }
        if (selectedMentor) {
            filtered = filtered.filter((data: BlockSchedule) => data.mentor === selectedMentor);
        }
        if (selectedMentee) {
            filtered = filtered.filter((data: BlockSchedule) => data.mentee === selectedMentee);
        }
        if (selectedStartDate) {
            filtered = filtered.filter((data: BlockSchedule) => moment(data.startTime).isSame(selectedStartDate, 'day'));
        }
        if (selectedEndDate) {
            filtered = filtered.filter((data: BlockSchedule) => moment(data.endTime).isSame(selectedEndDate, 'day'));
        }

        setFilteredData(filtered);
    };

    const handleChangeFilter = (setFilter: React.Dispatch<React.SetStateAction<string>>) => (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        setFilter(value);
    };

    const handleEditClick = (blockSchedule: BlockSchedule) => {
        handleEdit(blockSchedule);
    };

    const getFilteredOptions = (key: keyof BlockSchedule, data: BlockSchedule[]) => {
        return Array.from(new Set(data.map(item => item[key]))).filter(option => option !== null) as string[];
    };

    const getFilteredDateOptions = (key: keyof BlockSchedule, data: BlockSchedule[]) => {
        return Array.from(new Set(data.map(item => moment(item[key]).format("YYYY-MM-DD")))).sort();
    };

    const columnas: GridColDef[] = [
        { headerName: "ID", field: "id", flex: 0.5, minWidth: 80 },
        { headerName: "Evento", field: "eventName", flex: 1, minWidth: 150 },
        { headerName: "Actividad", field: "activityName", flex: 1, minWidth: 150 },
        { headerName: "Temática", field: "activityType", flex: 1, minWidth: 150 },
        {
            headerName: "Inicio", field: "startTime", flex: 1, minWidth: 130,
            renderCell: (cellvalues) => {
                const datetime = moment(cellvalues.value);
                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div>{datetime.format("DD-MM-YYYY")}</div>
                        <div>{datetime.format("HH:mm")}</div>
                    </div>
                );
            }
        },
        {
            headerName: "Fin", field: "endTime", flex: 1, minWidth: 130,
            renderCell: (cellvalues) => {
                const datetime = moment(cellvalues.value);
                return (
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div>{datetime.format("DD-MM-YYYY")}</div>
                        <div>{datetime.format("HH:mm")}</div>
                    </div>
                );
            }
        },
        { headerName: "Mentor", field: "mentor", flex: 1, minWidth: 150 },
        { headerName: "Mentee", field: "mentee", flex: 1, minWidth: 150 },
        {
            headerName: "Opciones", field: "opciones", flex: 1, minWidth: 120, maxWidth: 150,
            renderCell: (cellvalues) => (
                <div className='d-flex flex-column justify-content-center align-items-stretch w-100'>
                    <button className="btn btn-secondary btn-block p-1" onClick={() => handleEditClick(cellvalues.row)}>Modificar</button>
                </div>
            )
        }
    ];

    return isLoading ? (
        <div className='table-container-loading'>
            <p>Cargando horarios disponibles...</p>
        </div>
    ) : (
        <div className='table-container'>
            <Box sx={{ marginBottom: 2, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                {/* Filtro de Evento */}
                <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
                    <Select
                        value={selectedEvent}
                        onChange={handleChangeFilter(setSelectedEvent)}
                        displayEmpty
                        renderValue={(value) => value === '' ? 'Evento' : (value as string)}
                        inputProps={{ 'aria-label': 'Evento' }}
                        sx={{ fontSize: '0.90rem', height: 30, '.MuiSelect-select': { padding: '10px 10px' } }}
                    >
                        <MenuItem value="">
                            <em>Todos los eventos</em>
                        </MenuItem>
                        {getFilteredOptions('eventName', filteredData).map((eventName, index) => (
                            <MenuItem key={index} value={eventName}>{eventName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                
                {/* Filtro de Actividad */}
                <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
                    <Select
                        value={selectedActivity}
                        onChange={handleChangeFilter(setSelectedActivity)}
                        displayEmpty
                        renderValue={(value) => value === '' ? 'Actividad' : (value as string)}
                        inputProps={{ 'aria-label': 'Actividad' }}
                        sx={{ fontSize: '0.90rem', height: 30, '.MuiSelect-select': { padding: '10px 10px' } }}
                    >
                        <MenuItem value="">
                            <em>Todas las actividades</em>
                        </MenuItem>
                        {getFilteredOptions('activityName', filteredData).map((activityName, index) => (
                            <MenuItem key={index} value={activityName}>{activityName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Filtro de Temática */}
                <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
                    <Select
                        value={selectedTheme}
                        onChange={handleChangeFilter(setSelectedTheme)}
                        displayEmpty
                        renderValue={(value) => value === '' ? 'Temática' : (value as string)}
                        inputProps={{ 'aria-label': 'Temática' }}
                        sx={{ fontSize: '0.90rem', height: 30, '.MuiSelect-select': { padding: '10px 10px' } }}
                    >
                        <MenuItem value="">
                            <em>Todas las temáticas</em>
                        </MenuItem>
                        {getFilteredOptions('activityType', filteredData).map((activityType, index) => (
                            <MenuItem key={index} value={activityType}>{activityType}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Filtro de Fecha de Inicio */}
                <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
                    <Select
                        value={selectedStartDate}
                        onChange={handleChangeFilter(setSelectedStartDate)}
                        displayEmpty
                        renderValue={(value) => value === '' ? 'Inicio' : (value as string)}
                        inputProps={{ 'aria-label': 'Inicio' }}
                        sx={{ fontSize: '0.90rem', height: 30, '.MuiSelect-select': { padding: '10px 10px' } }}
                    >
                        <MenuItem value="">
                            <em>Todas las fechas de inicio</em>
                        </MenuItem>
                        {getFilteredDateOptions('startTime', filteredData).map((date, index) => (
                            <MenuItem key={index} value={date}>{moment(date).format("DD-MM-YYYY")}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Filtro de Fecha de Fin */}
                <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
                    <Select
                        value={selectedEndDate}
                        onChange={handleChangeFilter(setSelectedEndDate)}
                        displayEmpty
                        renderValue={(value) => value === '' ? 'Fin' : (value as string)}
                        inputProps={{ 'aria-label': 'Fin' }}
                        sx={{ fontSize: '0.90rem', height: 30, '.MuiSelect-select': { padding: '10px 10px' } }}
                    >
                        <MenuItem value="">
                            <em>Todas las fechas de fin</em>
                        </MenuItem>
                        {getFilteredDateOptions('endTime', filteredData).map((date, index) => (
                            <MenuItem key={index} value={date}>{moment(date).format("DD-MM-YYYY")}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Filtro de Mentor */}
                <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
                    <Select
                        value={selectedMentor}
                        onChange={handleChangeFilter(setSelectedMentor)}
                        displayEmpty
                        renderValue={(value) => value === '' ? 'Mentor' : (value as string)}
                        inputProps={{ 'aria-label': 'Mentor' }}
                        sx={{ fontSize: '0.90rem', height: 30, '.MuiSelect-select': { padding: '10px 10px' } }}
                    >
                        <MenuItem value="">
                            <em>Todos los mentores</em>
                        </MenuItem>
                        {getFilteredOptions('mentor', filteredData).map((mentor, index) => (
                            <MenuItem key={index} value={mentor}>{mentor}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Filtro de Mentee */}
                <FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
                    <Select
                        value={selectedMentee}
                        onChange={handleChangeFilter(setSelectedMentee)}
                        displayEmpty
                        renderValue={(value) => value === '' ? 'Mentee' : (value as string)}
                        inputProps={{ 'aria-label': 'Mentee' }}
                        sx={{ fontSize: '0.90rem', height: 30, '.MuiSelect-select': { padding: '10px 10px' } }}
                    >
                        <MenuItem value="">
                            <em>Todos los mentees</em>
                        </MenuItem>
                        {getFilteredOptions('mentee', filteredData).map((mentee, index) => (
                            <MenuItem key={index} value={mentee}>{mentee}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            {/* Componente Tabla con control de paginación */}
            <Tabla
                columnas={columnas}
                datos={filteredData}
                pageSize={pageSize}
                setPageSize={setPageSize}  // Pasar setPageSize directamente
                page={page}
                onPageChange={setPage}  // Pasar setPage directamente como onPageChange
                getRowId={(row: BlockSchedule) => row.id}
            />
        </div>
    );
};

export default BlockScheduleTable;
